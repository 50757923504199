import { useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '../utils/api';
import { AxiosResponse } from 'axios';
import { Action, CreateAction, UpdateAction } from '../types/common/api';
import { useMainData } from '../providers/MainDataProvider';

export const useActionMutations = (clubId?: string) => {
  const { isTestEnvironment } = useMainData();

  const actionsKey = [
    'rexActions',
    clubId,
    ...(isTestEnvironment ? ['test'] : []),
  ];

  const queryClient = useQueryClient();

  const { data: actions, refetch: refetchActions } = useQuery(
    actionsKey,
    async () =>
      api
        .get(`action${clubId ? `?clubId=${clubId}` : ''}`)
        .then(({ data }: AxiosResponse<Action[], any>) => data)
  );

  // Mutations
  const createAction = useMutation(
    (action: CreateAction) => {
      return api.post(`action`, action, {});
    },
    {
      onSuccess: ({ data: action }: AxiosResponse<Action, any>) => {
        queryClient.setQueryData(actionsKey, (actions: Action[] | undefined) =>
          actions ? [...actions, action] : [action]
        );
      },
    }
  );

  const updateAction = useMutation(
    ({ id, action }: { id: number; action: UpdateAction }) => {
      return api.put(`action/${id}`, action, {});
    },
    {
      onSuccess: ({ data: action }: AxiosResponse<Action, any>) => {
        queryClient.setQueryData(
          actionsKey,
          (actions: Action[] | undefined) =>
            actions?.map((a) => (a.id === action.id ? action : a)) || [action]
        );
      },
    }
  );

  return { actions, refetchActions, createAction, updateAction };
};
