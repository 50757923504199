import { GridColDef } from '@mui/x-data-grid';
import { BasicPage } from '../../components/templates/BasicPage';
import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import {
  defaultMargin,
  largeMargin,
  largerMargin,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import arrowLeftIcon from '../../assets/icons/arrow-narrow-left.svg';
import sendIcon from '../../assets/icons/send-gray.svg';
import { Link, useNavigate } from 'react-router-dom';
import { CustomGrid } from '../../components/common/CustomGrid';
import { ReportTypeChip } from '../../components/common/chip/ReportTypeChip';
import { FlightPhaseChip } from '../../components/common/chip/FlightPhaseChip';
import { FlightPhase, Rex } from '../../types/common/api';
import { useRexsToPublishMutations } from '../../hooks/useRexsToPublishMutations';
import { useAuth } from '../../providers/AuthProvider';
import { CustomTooltip } from '../../components/common/CustomTooltip';
import { useEffect, useRef, useState } from 'react';
import { RexPublicationDialog } from '../../components/dialog/RexPublicationDialog';
import { RexStatusList } from '../../types/common/main';
import { useRexVisibilities } from '../../hooks/useRexVisibilities';

export const RexToPublish = () => {
  const { hasCPSPermission } = useAuth();
  const { rexsToPublish, publishRex } = useRexsToPublishMutations();
  const { rexVisibilities } = useRexVisibilities();
  const navigate = useNavigate();

  const [publicationDialogOpen, setPublicationDialogOpen] =
    useState<boolean>(false);
  const [publishedRex, setPublishedRex] = useState<Rex>();

  const publicationInProgress = useRef(false);

  useEffect(() => {
    if (!publicationDialogOpen) {
      setPublishedRex(undefined);
    }
  }, [publicationDialogOpen]);

  useEffect(() => {
    if (publishedRex) setPublicationDialogOpen(true);
  }, [publishedRex]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 2,
    },
    {
      field: 'event_date',
      headerName: s.common.grid.eventDate,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const eventDateString = row?.event_date;
        const date = eventDateString?.split('-')?.reverse()?.join('/');
        return date;
      },
    },
    {
      field: 'flight_phase',
      headerName: s.rex.reportToProcess.grid.flightPhase,
      renderCell: (params) => {
        const row = params.row;
        const flightPhase: FlightPhase = row?.flight_phase;
        return flightPhase ? (
          <FlightPhaseChip flightPhase={flightPhase} />
        ) : (
          <p>N/A</p>
        );
      },
      flex: 1,
    },
    {
      field: 'rex_type',
      headerName: s.common.grid.rexType,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.rex_type ? (
          <ReportTypeChip reportType={row.rex_type} />
        ) : (
          <p>N/A</p>
        );
      },
    },
    {
      field: 'flight_type',
      headerName: s.common.grid.flightType,
      flex: 1,
    },
    {
      field: 'event_kind',
      headerName: s.common.grid.classification,
      flex: 1,
    },
    ...(hasCPSPermission
      ? [
          {
            field: 'edit',
            headerName: '',
            flex: 1,
            renderCell: (params: any) => {
              const row = params.row;
              const rexId = row?.id;

              return (
                <>
                  <CustomTooltip text={s.common.tooltip.publish}>
                    <img
                      src={sendIcon}
                      alt="Publish icon"
                      onClick={(e) => {
                        publicationInProgress.current = true;
                        if (publicationInProgress.current) return;

                        publishRex
                          .mutateAsync(rexId)
                          .then(({ data }) => {
                            setPublishedRex(data);
                          })
                          .finally(() => {
                            publicationInProgress.current = false;
                          });
                        e.stopPropagation();
                      }}
                      style={{ cursor: 'pointer', padding: smallMargin }}
                    />
                  </CustomTooltip>
                </>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <BasicPage
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: palette.gray50,
            paddingRight: largerMargin,
            paddingLeft: largerMargin,
            paddingTop: defaultMargin,
            paddingBottom: defaultMargin,
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
          <Link
            to={'./..'}
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            <img
              src={arrowLeftIcon}
              alt=""
              style={{ marginRight: tinyMargin }}
            />
            <p
              style={{
                ...fonts.medium14,
                color: palette.gray600,
                margin: 0,
              }}
            >
              {s.common.button.back}
            </p>
          </Link>
          <p style={{ ...fonts.bold30, margin: 0, marginTop: defaultMargin }}>
            {s.rex.home.rexToPublish}
          </p>
        </div>
        <div
          style={{
            marginTop: largeMargin,
            marginLeft: largeMargin * 2,
            marginRight: largeMargin * 2,
          }}
        >
          <CustomGrid
            rows={rexsToPublish || []}
            columns={columns}
            onRowClick={(row) => navigate('/rex/' + row.id)}
          />
        </div>
      </BasicPage>
      <RexPublicationDialog
        state={[publicationDialogOpen, setPublicationDialogOpen]}
        waitingValidation={
          publishedRex?.rex_status_id ===
          Object.values(RexStatusList).findIndex(
            (status) => status === RexStatusList.WaitingForValidation
          ) +
            1
        }
        visibilityLevel={
          rexVisibilities?.find(
            (visibility) => visibility.id === publishedRex?.rex_visibility_id
          )?.name || ''
        }
      />
    </>
  );
};
