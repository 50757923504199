import environment from '../../environment';
import { useMainData } from '../../providers/MainDataProvider';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import { defaultMargin, tinyMargin } from '../../styles/styles';
import { BasicFile } from '../../types/common/main';
import greenAddButton from '../../assets/icons/green-add-button.svg';
import blueAddButton from '../../assets/icons/blue-add-button.svg';

const MAX_FILES = 3;

interface Props {
  label: string;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedFiles: BasicFile[];
  uploadFrame: string;
  testUploadFrame: string;
  renderItem: ({
    uri,
    fileName,
    index,
  }: {
    uri: string;
    fileName: string | undefined;
    index: number;
  }) => React.ReactNode;
  type: 'image' | 'document';
}

export const AddAttachmentsInput = ({
  label,
  selectedFiles,
  uploadFrame,
  testUploadFrame,
  handleFileChange,
  renderItem,
  type,
}: Props) => {
  const { isTestEnvironment } = useMainData();

  const mediaBaseUrl = isTestEnvironment
    ? environment.testApiUrl
    : environment.apiUrl;

  const accept =
    type === 'image'
      ? 'image/*'
      : 'application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv';
  const inputId = type === 'image' ? 'image-picker' : 'document-picker';

  const containerStyle: React.CSSProperties =
    type === 'image'
      ? {
          display: 'flex',
          flexWrap: 'wrap',
          gap: defaultMargin,
          alignItems: 'center',
        }
      : {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          gap: tinyMargin,
        };

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: tinyMargin,
        }}
      >
        <p
          style={{
            ...fonts.medium14,
            color: palette.gray700,
            margin: 0,
            marginBottom: 0,
          }}
        >
          {label}
        </p>
      </div>
      <div style={containerStyle}>
        {selectedFiles &&
          selectedFiles.length > 0 &&
          Array.from({ length: selectedFiles.length }).map((_, index) => {
            const uri =
              selectedFiles?.at(index)?.uri?.replace(/\\/g, '/') || '';
            const isRemoteFile = uri?.startsWith('uploads/');

            const fullUri = isRemoteFile ? `${mediaBaseUrl}/${uri}` : uri;
            const filename =
              selectedFiles?.at(index)?.filename || uri?.split('/').pop();

            return renderItem({
              uri: fullUri,
              fileName: filename,
              index,
            });
          })}
        {!selectedFiles ||
          (selectedFiles?.length < MAX_FILES && (
            <>
              <label htmlFor={inputId} style={{ cursor: 'pointer' }}>
                {selectedFiles?.length === 0 ? (
                  <img
                    src={isTestEnvironment ? testUploadFrame : uploadFrame}
                    alt="placeholder"
                    // onClick={onClick}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <img
                    src={isTestEnvironment ? greenAddButton : blueAddButton}
                    alt="Add button"
                  />
                )}
              </label>
              <input
                id={inputId}
                type="file"
                accept={accept}
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </>
          ))}
      </div>
    </div>
  );
};
