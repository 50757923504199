import { GridColDef } from '@mui/x-data-grid';
import { BasicPage } from '../../components/templates/BasicPage';
import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import {
  defaultMargin,
  largeMargin,
  largerMargin,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import arrowLeftIcon from '../../assets/icons/arrow-narrow-left.svg';
import classicPenIcon from '../../assets/icons/classic-pen.svg';
import trashIcon from '../../assets/icons/trash.svg';
import { Link, useNavigate } from 'react-router-dom';
import { CustomGrid } from '../../components/common/CustomGrid';
import { ReportTypeChip } from '../../components/common/chip/ReportTypeChip';
import { FlightPhaseChip } from '../../components/common/chip/FlightPhaseChip';
import { FlightPhase } from '../../types/common/api';
import { useRexsToCompleteMutations } from '../../hooks/useRexsToCompleteMutations';
import { useAuth } from '../../providers/AuthProvider';
import { CustomTooltip } from '../../components/common/CustomTooltip';
import { DeleteDialog } from '../../components/common/DeleteDialog';
import { useState } from 'react';

export const RexToCompletePage = () => {
  const { hasCPSPermission, clubsWithCPSAccess } = useAuth();
  const { rexsToComplete, removeRexToComplete } = useRexsToCompleteMutations();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 2,
    },
    {
      field: 'event_date',
      headerName: s.common.grid.eventDate,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const eventDateString = row?.event_date;
        const date = eventDateString?.split('-')?.reverse()?.join('/');
        return date;
      },
    },
    {
      field: 'flight_phase',
      headerName: s.rex.reportToProcess.grid.flightPhase,
      renderCell: (params) => {
        const row = params.row;
        const flightPhase: FlightPhase = row?.flight_phase;
        return flightPhase ? (
          <FlightPhaseChip flightPhase={flightPhase} />
        ) : (
          <p>N/A</p>
        );
      },
      flex: 1,
    },
    {
      field: 'rex_type',
      headerName: s.common.grid.rexType,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.rex_type ? (
          <ReportTypeChip reportType={row.rex_type} />
        ) : (
          <p>N/A</p>
        );
      },
    },
    {
      field: 'flight_type',
      headerName: s.common.grid.flightType,
      flex: 1,
    },
    {
      field: 'event_kind',
      headerName: s.common.grid.classification,
      flex: 1,
    },
    {
      field: 'edit',
      headerName: '',
      flex: 1,
      renderCell: (params: any) => {
        const row = params.row;
        const rexId = row?.id;
        const clubId = row?.club_id;

        if (
          clubsWithCPSAccess?.findIndex(
            (club) => club.ree_idx?.toString() === clubId
          ) === -1
        )
          return null;

        return (
          <>
            <DeleteDialog
              onDelete={() => {
                removeRexToComplete.mutateAsync(Number(rexId));
              }}
              title={s.rex.newRex.completeRex.deleteRexConfirmTitle}
              description={s.rex.newRex.completeRex.deleteRexConfirmDescription}
            >
              <CustomTooltip text={s.common.tooltip.delete}>
                <img
                  src={trashIcon}
                  alt="Delete icon"
                  style={{ cursor: 'pointer', padding: smallMargin }}
                />
              </CustomTooltip>
            </DeleteDialog>
            <CustomTooltip text={s.common.tooltip.edit}>
              <img
                src={classicPenIcon}
                alt="Edit icon"
                onClick={(e) => {
                  navigate(`${rexId}`);
                  e.stopPropagation();
                }}
                style={{ cursor: 'pointer', padding: smallMargin }}
              />
            </CustomTooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <BasicPage
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: palette.gray50,
            paddingRight: largerMargin,
            paddingLeft: largerMargin,
            paddingTop: defaultMargin,
            paddingBottom: defaultMargin,
            textDecoration: 'none',
            cursor: 'pointer',
          }}
        >
          <Link
            to={'./..'}
            style={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
          >
            <img
              src={arrowLeftIcon}
              alt=""
              style={{ marginRight: tinyMargin }}
            />
            <p
              style={{
                ...fonts.medium14,
                color: palette.gray600,
                margin: 0,
              }}
            >
              {s.common.button.back}
            </p>
          </Link>
          <p style={{ ...fonts.bold30, margin: 0, marginTop: defaultMargin }}>
            {s.rex.home.rexToComplete}
          </p>
        </div>
        <div
          style={{
            marginTop: largeMargin,
            marginLeft: largeMargin * 2,
            marginRight: largeMargin * 2,
          }}
        >
          <CustomGrid
            rows={rexsToComplete || []}
            columns={columns}
            onRowClick={(row) =>
              hasCPSPermission
                ? navigate(`${row.id}`)
                : navigate('/rex/' + row.id)
            }
          />
        </div>
      </BasicPage>
    </>
  );
};
