import { AppBar, Container, Stack, Toolbar } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import logoWhite from '../../assets/images/logo-white.svg';
import websiteLogo from '../../assets/icons/social/website.svg';
import logoutWhiteIcon from '../../assets/icons/log-out-white.svg';
import menuBookmark from '../../assets/icons/menu-bookmark.svg';
import menuGear from '../../assets/icons/menu-gear.svg';
import menuHome from '../../assets/icons/menu-home.svg';
import menuSearch from '../../assets/icons/menu-search.svg';
import menuSettings from '../../assets/icons/menu-settings.svg';
import menuBookmarkTest from '../../assets/icons/menu-bookmark-test.svg';
import menuGearTest from '../../assets/icons/menu-gear-test.svg';
import menuHomeTest from '../../assets/icons/menu-home-test.svg';
import menuSearchTest from '../../assets/icons/menu-search-test.svg';
import menuSettingsTest from '../../assets/icons/menu-settings-test.svg';
import { fonts } from '../../styles/fonts';
import { colors, palette } from '../../styles/colors';
import { useEffect, useState } from 'react';
import { s } from '../../strings/strings';
import { useAuth } from '../../providers/AuthProvider';
import {
  defaultMargin,
  largeMargin,
  largerMargin,
  smallMargin,
} from '../../styles/styles';
import { useMainData } from '../../providers/MainDataProvider';
import { CustomTooltip } from '../common/CustomTooltip';

interface BasicPageProps {
  children?: any;
  style?: React.CSSProperties | undefined;
  hideNav?: boolean;
}

export const BasicPage: React.FC<BasicPageProps> = ({
  children,
  style,
  hideNav,
}) => {
  return (
    <>
      <div
        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
      >
        <Header hideNav={hideNav} />
        <div
          style={{
            flexGrow: 1,
            backgroundColor: palette.white,
            paddingTop: defaultMargin + smallMargin,
            paddingRight: largerMargin,
            paddingBottom: defaultMargin + smallMargin,
            paddingLeft: largerMargin,
            ...style,
          }}
        >
          {children}
        </div>
      </div>
      <Footer />
    </>
  );
};

interface HeaderProps {
  hideNav?: boolean;
}

const Header = ({ hideNav = false }: HeaderProps) => {
  const { primaryColors, isTestEnvironment } = useMainData();
  const location = useLocation();
  const { user, logout, hasCPSPermission } = useAuth();
  const navigate = useNavigate();

  const [currentPathName, setCurrentPathName] = useState<string>();

  useEffect(() => {
    setCurrentPathName(location.pathname);
  }, [location]);

  const routes = [
    {
      title: s.pages.dashboard,
      path: '/dashboard',
      selectedIcon: isTestEnvironment ? menuHomeTest : menuHome,
    },
    ...(hasCPSPermission
      ? [
          {
            title: s.pages.rex,
            path: '/rex',
            selectedIcon: isTestEnvironment ? menuGearTest : menuGear,
          },
        ]
      : []),
    {
      title: s.pages.search,
      path: '/search',
      selectedIcon: isTestEnvironment ? menuSearchTest : menuSearch,
    },
    {
      title: s.pages.favorites,
      path: '/favorites',
      selectedIcon: isTestEnvironment ? menuBookmarkTest : menuBookmark,
    },
    {
      title: s.pages.settings,
      path: '/settings',
      selectedIcon: isTestEnvironment ? menuSettingsTest : menuSettings,
    },
  ];

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
          borderTopStyle: 'solid',
          borderTopWidth: 0,
          background: primaryColors.primary,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                marginRight: hideNav ? 'auto' : undefined,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Link
                to={user ? '/dashboard' : '/login'}
                style={{
                  cursor: 'pointer',
                }}
              >
                <img
                  src={isTestEnvironment ? logo : logoWhite}
                  alt="REX FFA Logo"
                />
              </Link>
              {isTestEnvironment && (
                <p
                  style={{
                    ...fonts.semiBold16,
                    color: colors.textWhite,
                    margin: 0,
                    marginLeft: smallMargin,
                  }}
                >
                  Formation
                </p>
              )}
            </div>
            {!hideNav && (
              <>
                <Stack
                  spacing="16px"
                  direction="row"
                  style={{ alignItems: 'center' }}
                >
                  {routes.map((route, index) => {
                    const isSelected = currentPathName?.startsWith(route.path);
                    return (
                      <Link
                        key={index}
                        to={route.path}
                        style={
                          isSelected
                            ? {
                                ...styles.selectedLink,
                                color: primaryColors.primary,
                              }
                            : {
                                ...styles.link,
                                color: palette.white,
                              }
                        }
                      >
                        {isSelected && (
                          <div style={{ color: 'blue' }}>
                            <img
                              src={route.selectedIcon}
                              alt=""
                              color="red"
                              style={{
                                marginRight: 15,
                                color: primaryColors.primary,
                              }}
                            />
                          </div>
                        )}
                        {route.title}
                      </Link>
                    );
                  })}
                </Stack>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <p
                    style={{
                      ...fonts.semiBold14,
                      color: palette.white,
                      marginRight: 10,
                    }}
                  >
                    {user ? user?.name : ''}
                  </p>
                  <CustomTooltip text={s.common.tooltip.logout}>
                    <img
                      src={logoutWhiteIcon}
                      alt="REX FFA Logo"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        logout().then(() => {
                          navigate('/login');
                        });
                      }}
                    />
                  </CustomTooltip>
                </div>
              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

const Footer = () => {
  const { primaryColors } = useMainData();

  return (
    <>
      <div
        style={{
          backgroundColor: primaryColors.primary,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexGrow: 1,
          paddingTop: largeMargin,
          paddingRight: largerMargin,
          paddingBottom: largeMargin,
          paddingLeft: largerMargin,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <Link to="/dashboard" style={{ cursor: 'pointer' }}>
            <img src={logoWhite} alt="REX FFA Logo" />
          </Link>
          <Stack
            spacing={`${smallMargin}px`}
            direction="row"
            style={{ alignItems: 'center', marginTop: defaultMargin }}
          >
            <img
              src={websiteLogo}
              alt="FFA Aero Website"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  'https://ffa-aero.fr/FR/Federation_Aeronautique.awp',
                  '_blank'
                );
              }}
            />
          </Stack>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Stack
            spacing={`${defaultMargin + smallMargin}px`}
            direction="row"
            style={{ alignItems: 'center', justifyContent: 'flex-end' }}
          >
            <Link to="/privacy-policy" style={styles.footerLink}>
              {s.footer.privacyPolicy}
            </Link>
            <Link to="/gcu" style={styles.footerLink}>
              {s.footer.TOS}
            </Link>
          </Stack>
          <p
            style={{
              ...fonts.regular14,
              margin: 0,
              color: palette.white,
              marginTop: defaultMargin,
              textAlign: 'end',
            }}
          >
            {s.footer.copyright}
          </p>
        </div>
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  link: {
    ...fonts.semiBold16,
    color: palette.gray500,
    textDecoration: 'none',
    padding: '8px 12px',
  },
  selectedLink: {
    ...fonts.semiBold16,
    textDecoration: 'none',
    padding: '8px 12px',
    borderRadius: 6,
    backgroundColor: palette.darkBlue50,
    display: 'flex',
    alignItems: 'center',
  },
  footerLink: {
    ...fonts.semiBold14,
    textDecoration: 'none',
    cursor: 'pointer',
    marginTop: 0,
    marginBottom: 0,
    color: palette.white,
  },
};
