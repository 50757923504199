import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { CustomGrid } from '../../common/CustomGrid';
import { Rex } from '../../../types/common/api';
import { s } from '../../../strings/strings';
import { AssociatedRexList } from '../../common/AssociatedRexList';

interface ActionSearchResultGridProps {
  apiRef?: React.MutableRefObject<GridApiCommunity>;
  onRowClick?: GridEventListener<'rowClick'> | undefined;
  results: Rex[];
}

export const ActionSearchResultGrid: React.FC<ActionSearchResultGridProps> = ({
  apiRef,
  onRowClick,
  results,
}) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'description',
      headerName: s.common.grid.description,
      flex: 4,
    },
    {
      field: 'created_at',
      headerName: s.common.grid.date,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.created_at
          ? new Date(row?.created_at)
              ?.toISOString()
              ?.split('T')
              ?.shift()
              ?.split('-')
              ?.reverse()
              ?.join('/')
          : 'N/A';
      },
    },
    {
      field: 'status_date',
      headerName: s.common.grid.statusDate,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.status_date
          ? new Date(row?.status_date)
              ?.toISOString()
              ?.split('T')
              ?.shift()
              ?.split('-')
              ?.reverse()
              ?.join('/')
          : 'N/A';
      },
    },
    {
      field: 'carrier_name',
      headerName: s.common.grid.carrier,
      flex: 1,
    },
    {
      field: 'associated_rexs',
      headerName: s.common.grid.associatedRexs,
      flex: 3,
      renderCell: (params) => {
        const rexIds =
          params?.row?.rexs?.map((rex: { id: string }) => rex?.id) || [];
        return <AssociatedRexList ids={rexIds} />;
      },
    },
  ];

  return (
    <>
      <CustomGrid
        apiRef={apiRef}
        rows={results || []}
        columns={columns}
        onRowClick={onRowClick}
        hideFooter={false}
      />
    </>
  );
};
