import { mediumRadius } from '../../../styles/styles';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { CustomGrid } from '../../common/CustomGrid';
import { Rex } from '../../../types/common/api';
import { s } from '../../../strings/strings';
import { FlightPhaseChip } from '../../common/chip/FlightPhaseChip';
import { ReportTypeChip } from '../../common/chip/ReportTypeChip';
import { getRiskColor } from '../../../utils/main';
import { useAuth } from '../../../providers/AuthProvider';
import { useFlightPhases } from '../../../hooks/useFlightPhases';

interface RexSearchResultGridProps {
  apiRef?: React.MutableRefObject<GridApiCommunity>;
  onRowClick?: GridEventListener<'rowClick'> | undefined;
  results: Rex[];
}

export const RexSearchResultGrid: React.FC<RexSearchResultGridProps> = ({
  apiRef,
  onRowClick,
  results,
}) => {
  const { isMemberOfClub, clubsWithCPSAccess } = useAuth();
  const { flightPhases } = useFlightPhases();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 2,
    },
    {
      field: 'rex_type',
      headerName: s.common.grid.rexType,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.rex_type ? (
          <ReportTypeChip reportType={row.rex_type} />
        ) : (
          <p>N/A</p>
        );
      },
    },
    {
      field: 'event_date',
      headerName: s.common.grid.date,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        const eventDateString = row?.event_date;
        const date = eventDateString?.split('-')?.reverse()?.join('/');
        return date;
      },
    },
    {
      field: 'flight_phase',
      headerName: s.rex.reportToProcess.grid.flightPhase,
      renderCell: (params) => {
        const row = params.row;
        const flightPhaseId = row?.flight_phase_id;
        const flightPhase = flightPhases?.find(
          (flightPhase) => flightPhase.id === flightPhaseId
        );

        return flightPhase ? (
          <FlightPhaseChip flightPhase={flightPhase} />
        ) : (
          <p>N/A</p>
        );
      },
      flex: 1,
    },
    {
      field: 'flight_kind',
      headerName: s.common.grid.flightKind,
      flex: 1,
    },
    {
      field: 'flight_type',
      headerName: s.common.grid.flightType,
      flex: 1,
    },
    {
      field: 'aircraft_registration',
      headerName: s.common.grid.registration,
      renderCell: (params) => {
        const row = params.row;
        const clubId = row?.club_id || '';

        const hasAccessToSensitiveData =
          isMemberOfClub(clubId) &&
          clubsWithCPSAccess?.some(
            (club) => club.ree_idx?.toString() === clubId?.toString()
          );

        return hasAccessToSensitiveData ? row?.aircraft_registration : '';
      },
      flex: 1,
    },
    {
      field: 'event_kind',
      headerName: s.common.grid.classification,
      flex: 1,
    },
    {
      field: 'initial_risk_probability',
      headerName: s.common.grid.risk,
      renderCell: (params) => {
        const row = params.row;
        const severity: number = row?.initial_risk_severity;
        const probability: number = row?.initial_risk_probability;
        return (
          <>
            <div
              style={{
                display: 'flex',
                backgroundColor: getRiskColor(severity, probability),
                borderRadius: mediumRadius,
                height: 18,
                width: 18,
              }}
            ></div>
          </>
        );
      },
      flex: 0.5,
    },
  ];

  return (
    <>
      <CustomGrid
        apiRef={apiRef}
        rows={results || []}
        columns={columns}
        onRowClick={onRowClick}
        hideFooter={false}
      />
    </>
  );
};
