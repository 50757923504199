import { mediumRadius } from '../../../styles/styles';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { CustomGrid } from '../../common/CustomGrid';
import { FlightPhase, Rex } from '../../../types/common/api';
import { s } from '../../../strings/strings';
import { FlightPhaseChip } from '../../common/chip/FlightPhaseChip';
import { ReportTypeChip } from '../../common/chip/ReportTypeChip';
import { getRiskColor } from '../../../utils/main';
import { AssociatedRexList } from '../../common/AssociatedRexList';

interface CauseSearchResultGridProps {
  apiRef?: React.MutableRefObject<GridApiCommunity>;
  onRowClick?: GridEventListener<'rowClick'> | undefined;
  results: Rex[];
}

export const CauseSearchResultGrid: React.FC<CauseSearchResultGridProps> = ({
  apiRef,
  onRowClick,
  results,
}) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: s.common.grid.id,
      flex: 1,
      renderCell: (params) => {
        const row = params.row;
        return row?.id;
      },
    },
    {
      field: 'title',
      headerName: s.common.grid.title,
      flex: 6,
    },
    {
      field: 'associated_rexs',
      headerName: s.common.grid.associatedRexs,
      flex: 3,
      renderCell: (params) => {
        const rexIds =
          params?.row?.rexs?.map(
            (causeRex: { rex: { id: string } }) => causeRex?.rex?.id
          ) || [];
        return <AssociatedRexList ids={rexIds} />;
      },
    },
  ];

  return (
    <>
      <CustomGrid
        apiRef={apiRef}
        rows={results || []}
        columns={columns}
        onRowClick={onRowClick}
        hideFooter={false}
      />
    </>
  );
};
