import { Link, useNavigate, useParams } from 'react-router-dom';
import { BasicPage } from '../../components/templates/BasicPage';
import { palette } from '../../styles/colors';
import {
  defaultMargin,
  largerMargin,
  smallMargin,
  tinyMargin,
} from '../../styles/styles';
import { fonts } from '../../styles/fonts';
import { s } from '../../strings/strings';
import arrowLeftIcon from '../../assets/icons/arrow-narrow-left.svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Rex, Action, RexToCause } from '../../types/common/api';
import { api } from '../../utils/api';
import { AxiosResponse } from 'axios';
import bookmarkIcon from '../../assets/icons/blue-bookmark.svg';
import bookmarkFilledIcon from '../../assets/icons/blue-bookmark-filled.svg';
import { CustomButton } from '../../components/common/CustomButton';
import { RexGridSummary } from '../../components/common/RexGridSummary';
import { Grid } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { CustomGrid } from '../../components/common/CustomGrid';
import imagePlaceholder from '../../assets/images/image-placeholder.svg';
import { RiskDisplay } from '../../components/common/RiskDisplay';
import { useFavoriteRexMutations } from '../../hooks/useFavoriteRexMutations';
import environment from '../../environment';
import { RexActionSelector } from '../../components/rex/RexActionSelector';
import { useRexsToPublishMutations } from '../../hooks/useRexsToPublishMutations';
import {
  Entity,
  ReportType,
  RexStatusList,
  RexVisibility,
} from '../../types/common/main';
import { useMainData } from '../../providers/MainDataProvider';
import { useAuth } from '../../providers/AuthProvider';
import { useQuery } from 'react-query';
import { useRexVisibilities } from '../../hooks/useRexVisibilities';
import { FFA_INDEX } from '../../constants';
import { CustomTooltip } from '../../components/common/CustomTooltip';
import { RexPublicationDialog } from '../../components/dialog/RexPublicationDialog';

export const RexResultPage = () => {
  const navigate = useNavigate();
  const { rexId } = useParams();
  const { addFavoriteRex, deleteFavoriteRex, favoriteRexs } =
    useFavoriteRexMutations();
  const { publishRex } = useRexsToPublishMutations();
  const { primaryColors, isTestEnvironment } = useMainData();
  const { clubsWithCPSAccess } = useAuth();
  const { rexVisibilities } = useRexVisibilities();

  const [rex, setRex] = useState<Rex>();
  const [isLoadingBookmark, setIsLoadingBookmark] = useState<boolean>(false);
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const [isPublishingRex, setIsPublishingRex] = useState<boolean>(false);
  const [publicationDialogOpen, setPublicationDialogOpen] =
    useState<boolean>(false);
  const [publishedRex, setPublishedRex] = useState<Rex>();

  const publicationInProgress = useRef(false);

  const { data: entityData } = useQuery(
    ['entity', rex?.club_id, ...(isTestEnvironment ? ['test'] : [])],
    async () =>
      await api
        .get(`smile/entity/${rex?.club_id}`)
        .then(({ data }: AxiosResponse<Entity, any>) => data),
    {
      enabled: !!rex?.club_id,
      staleTime: 1000 * 60,
    }
  );

  useEffect(() => {
    if (!publicationDialogOpen) {
      setPublishedRex(undefined);
    }
  }, [publicationDialogOpen]);

  useEffect(() => {
    if (publishedRex) setPublicationDialogOpen(true);
  }, [publishedRex]);

  useEffect(() => {
    api
      .get(`rex/${rexId}`)
      .then(({ data }: AxiosResponse<Rex, any>) => setRex(data));
  }, [rexId]);

  useEffect(() => {
    setIsBookmarked(
      favoriteRexs?.find((e) => e.id.toString() === rexId) ? true : false
    );
  }, [favoriteRexs, rexId]);

  const hasRexActionPermission = useMemo(() => {
    const isRexReady = rex?.rex_status?.name === RexStatusList.Ready;
    let rexVisibilityName = rexVisibilities?.find(
      (visibility) => visibility.id === rex?.rex_visibility_id
    )?.name;

    if (isRexReady) {
      rexVisibilityName = RexVisibility.Club;
    }

    let entitiesIdx = [rex?.club_id];

    if (rex?.club_id) {
      switch (rexVisibilityName) {
        case RexVisibility.FFA:
          entitiesIdx = [FFA_INDEX];
          break;
        case RexVisibility.CRA:
          entitiesIdx = [entityData?.ree_cra?.toString()];
          break;
        case RexVisibility.CDA:
          entitiesIdx = [entityData?.ree_cda?.toString()];
          break;
        case RexVisibility.Plateforme:
          entitiesIdx =
            entityData?.plateforme?.map((plateforme) =>
              plateforme.ree_idx?.toString()
            ) || [];
          break;
        case RexVisibility.InterClub:
          entitiesIdx =
            entityData?.interclub?.map((interClub) =>
              interClub.ree_idx?.toString()
            ) || [];
          break;
        case RexVisibility.Club:
        default:
          entitiesIdx = [rex?.club_id];
          break;
      }
    } else {
      entitiesIdx = [FFA_INDEX];
    }

    return (
      clubsWithCPSAccess?.findIndex((club) =>
        entitiesIdx.includes(club.ree_idx?.toString() || '')
      ) !== -1
    );
  }, [rex, clubsWithCPSAccess, entityData, rexVisibilities]);

  const isRexPublishable = useMemo(() => {
    if (!rex?.rex_status?.name) return false;

    return [RexStatusList.Ready, RexStatusList.WaitingForValidation].includes(
      rex?.rex_status?.name
    );
  }, [rex]);

  const isRexEditable = useMemo(() => {
    if (!rex?.rex_status?.name) return false;

    return [
      RexStatusList.InProgress,
      RexStatusList.Ready,
      RexStatusList.WaitingForValidation,
      RexStatusList.Published,
    ].includes(rex?.rex_status?.name);
  }, [rex]);

  return (
    <>
      <BasicPage
        style={{
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            backgroundColor: palette.gray50,
            paddingRight: largerMargin,
            paddingLeft: largerMargin,
            paddingTop: defaultMargin,
            paddingBottom: defaultMargin,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <img
                src={arrowLeftIcon}
                alt=""
                style={{ marginRight: tinyMargin }}
              />
              <p
                style={{
                  ...fonts.medium14,
                  color: palette.gray600,
                  margin: 0,
                }}
              >
                {s.searchResult.backToResults}
              </p>
            </Link>
            <p style={{ ...fonts.bold24, margin: 0, marginTop: defaultMargin }}>
              {rex?.title}
            </p>
            <p
              style={{
                ...fonts.medium16,
                margin: 0,
                marginTop: tinyMargin,
                whiteSpace: 'pre-line',
              }}
            >
              {rex?.description}
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: smallMargin,
            }}
          >
            <CustomTooltip
              text={
                isBookmarked
                  ? s.common.tooltip.removeFromFavorites
                  : s.common.tooltip.addToFavorites
              }
            >
              <CustomButton
                title={''}
                onClick={() => {
                  setIsLoadingBookmark(true);
                  (isBookmarked ? deleteFavoriteRex : addFavoriteRex)
                    .mutateAsync(Number(rexId))
                    .finally(() => {
                      setIsLoadingBookmark(false);
                    });
                }}
                style={{
                  ...fonts.medium14,
                  border: `1px solid ${palette.darkBlue600}`,
                  backgroundColor: palette.darkBlue50,
                  color: palette.darkBlue600,
                  flexGrow: 0,
                }}
                loading={isLoadingBookmark}
                loaderColor={primaryColors.primary}
                iconSrc={isBookmarked ? bookmarkFilledIcon : bookmarkIcon}
              />
            </CustomTooltip>
            {hasRexActionPermission && (
              <RexActionSelector rexId={rexId || ''} />
            )}
          </div>
        </div>
        <div
          style={{
            paddingTop: defaultMargin,
            paddingRight: largerMargin,
            paddingBottom: defaultMargin,
            paddingLeft: largerMargin,
          }}
        >
          <GeneralInfoSection
            rex={rex}
            hasRexActionPermission={hasRexActionPermission}
          />
          <CpsAnalysisSection rex={rex} />
          {hasRexActionPermission && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                columnGap: defaultMargin,
                width: '100%',
                justifyItems: 'flex-end',
              }}
            >
              {isRexEditable && (
                <CustomButton
                  title={s.common.button.editRex}
                  onClick={() => {
                    if (rexId) {
                      navigate(`/rex/complete/${rexId}`, { replace: true });
                    }
                  }}
                  loaderColor={primaryColors.primary}
                  style={{
                    ...fonts.medium14,
                    marginLeft: 'auto',
                    backgroundColor: isRexPublishable
                      ? 'transparent'
                      : palette.violet600,
                    color: isRexPublishable ? palette.gray600 : palette.white,
                    flexGrow: 0,
                  }}
                />
              )}
              {isRexPublishable && (
                <CustomButton
                  title={s.common.button.publishRex}
                  onClick={() => {
                    if (rexId) {
                      if (publicationInProgress.current) return;
                      publicationInProgress.current = true;
                      setIsPublishingRex(true);
                      publishRex
                        .mutateAsync(Number(rexId))
                        .then(({ data }) => {
                          setPublishedRex(data);
                        })
                        .finally(() => {
                          setIsPublishingRex(false);
                          publicationInProgress.current = false;
                        });
                    }
                  }}
                  loading={isPublishingRex}
                  style={{
                    ...fonts.medium14,
                    backgroundColor: palette.violet600,
                    color: palette.white,
                    flexGrow: 0,
                  }}
                />
              )}
            </div>
          )}
        </div>
      </BasicPage>
      <RexPublicationDialog
        state={[publicationDialogOpen, setPublicationDialogOpen]}
        waitingValidation={
          publishedRex?.rex_status_id ===
          Object.values(RexStatusList).findIndex(
            (status) => status === RexStatusList.WaitingForValidation
          ) +
            1
        }
        visibilityLevel={
          rexVisibilities?.find(
            (visibility) => visibility.id === publishedRex?.rex_visibility_id
          )?.name || ''
        }
        onClose={() => navigate('..')}
      />
    </>
  );
};

interface GeneralInfoSectionProps {
  rex?: Rex;
  hasRexActionPermission: boolean;
}

const GeneralInfoSection: React.FC<GeneralInfoSectionProps> = ({
  rex,
  hasRexActionPermission,
}) => {
  const { isTestEnvironment } = useMainData();
  const images = rex?.images || [];

  const getImageFullUri = (index: number) => {
    const imagePath = images?.at(index)?.path;
    if (!imagePath) return null;
    const uri = imagePath?.replace(/\\/g, '/');
    const fullUri = `${
      isTestEnvironment ? environment.testApiUrl : environment.apiUrl
    }/${uri}`;
    return fullUri;
  };

  const image1Uri = getImageFullUri(0);
  const image2Uri = getImageFullUri(1);
  const image3Uri = getImageFullUri(2);

  return (
    <>
      <p style={{ ...fonts.bold18 }}>{s.searchResult.generalInfo}</p>
      <Grid
        container
        rowSpacing={1.5}
        columnSpacing={{ xs: 1, sm: 3, md: 5 }}
        style={{ padding: defaultMargin }}
      >
        {images?.length > 0 && (
          <Grid item xs={3.5}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: defaultMargin,
              }}
            >
              {image1Uri && (
                <a href={image1Uri} target="_blank" rel="noreferrer">
                  <img
                    src={image1Uri || imagePlaceholder}
                    alt="report detail"
                    style={{
                      cursor: image1Uri ? 'pointer' : 'default',
                      width: '100%',
                      aspectRatio: 1.57,
                      objectFit: 'cover',
                    }}
                  />
                </a>
              )}
              <div
                style={{
                  display: 'flex',
                  gap: defaultMargin,
                  flexGrow: 1,
                }}
              >
                {image2Uri && (
                  <a
                    href={image2Uri}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      cursor: image2Uri ? 'pointer' : 'default',
                      display: 'flex',
                      flexGrow: 1,
                      flexShrink: 1,
                      minWidth: 20,
                    }}
                  >
                    <img
                      src={image2Uri || imagePlaceholder}
                      alt="report detail"
                      style={{
                        flexGrow: 1,
                        minWidth: 20,
                        aspectRatio: 1.57,
                        objectFit: 'cover',
                      }}
                    />
                  </a>
                )}
                {image3Uri && (
                  <a
                    href={image3Uri}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      cursor: image3Uri ? 'pointer' : 'default',
                      display: 'flex',
                      flexGrow: 1,
                      flexShrink: 1,
                      minWidth: 20,
                    }}
                  >
                    <img
                      src={image3Uri || imagePlaceholder}
                      alt="report detail"
                      style={{
                        flexGrow: 1,
                        minWidth: 20,
                        aspectRatio: 1.57,
                        objectFit: 'cover',
                      }}
                    />
                  </a>
                )}
              </div>
            </div>
          </Grid>
        )}
        <Grid item xs={8.5} style={styles.gridValue}>
          <RexGridSummary
            rex={rex}
            hasRexActionPermission={hasRexActionPermission}
          />
        </Grid>
      </Grid>
    </>
  );
};

interface CpsAnalysisSectionProps {
  rex?: Rex;
}

const CpsAnalysisSection: React.FC<CpsAnalysisSectionProps> = ({ rex }) => {
  return (
    <>
      <p style={{ ...fonts.bold18 }}>{s.searchResult.cpsAnalysis}</p>

      <Grid
        container
        rowSpacing={1.5}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        style={{ padding: defaultMargin }}
      >
        <Grid item xs={6}>
          <p style={styles.gridTitle}>{s.searchResult.eventSummary}</p>
          <p style={styles.gridValue}>{rex?.event_summary || 'N/A'}</p>
        </Grid>
        <Grid item xs={6} style={styles.gridValue}></Grid>
        <Grid item xs={6} style={styles.gridTitle}>
          <CausesGrid data={rex?.causes || []} />
        </Grid>
        <Grid item xs={6} style={styles.gridValue}>
          <ActionsGrid data={rex?.actions || []} />
        </Grid>
        <Grid item xs={6}>
          <p style={styles.gridTitle}>{s.searchResult.safetyEducation}</p>
          <p style={styles.gridValue}>{rex?.safety_education || 'N/A'}</p>
        </Grid>
        {rex?.rex_type !== ReportType.GoodPractice && (
          <Grid item xs={6} style={styles.gridValue}>
            <div style={{ display: 'flex', gap: defaultMargin }}>
              <RiskDisplay
                label={s.searchResult.initialRisk}
                severity={rex?.initial_risk_severity || 0}
                probability={rex?.initial_risk_probability || 0}
              />
              <RiskDisplay
                label={s.searchResult.residualRisk}
                severity={rex?.residual_risk_severity || 0}
                probability={rex?.residual_risk_probability || 0}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};

interface CausesGridProps {
  data: RexToCause[];
}

export const CausesGrid: React.FC<CausesGridProps> = ({ data }) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: s.modals.addCause.grid.title,
      flex: 2,
      valueGetter: (params) => params?.row?.cause?.title,
    },
    {
      field: 'cause_type',
      headerName: s.modals.addCause.grid.causeType,
      flex: 1,
    },
    {
      field: 'rex_count',
      headerName: s.modals.addCause.grid.rexCount,
      flex: 1,
      valueGetter: (params) => params?.row?.cause?._count?.rexs,
    },
  ];

  return (
    <>
      <div
        style={{
          marginTop: smallMargin,
          width: '100%',
        }}
      >
        <CustomGrid
          headerTitle={s.common.rex.causes}
          getRowId={(row) => row?.cause?.id}
          rows={data}
          columns={columns}
          onRowClick={(row) => navigate('/cause/' + row.id)}
        />
      </div>
    </>
  );
};
interface ActionsGridProps {
  data: Action[];
}

export const ActionsGrid: React.FC<ActionsGridProps> = ({ data }) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: s.modals.addAction.grid.description,
      flex: 2,
    },
    {
      field: 'status',
      headerName: s.modals.addAction.grid.status,
      flex: 1,
    },
    {
      field: 'carrier_name',
      headerName: s.modals.addAction.grid.carrier,
      flex: 1,
    },
    {
      field: 'rex_count',
      headerName: s.modals.addAction.grid.rexCount,
      flex: 1,
      valueGetter: (params) => params?.row?._count?.rexs,
    },
  ];

  return (
    <>
      <div
        style={{
          marginTop: smallMargin,
          width: '100%',
        }}
      >
        <CustomGrid
          headerTitle={s.common.rex.actions}
          rows={data}
          columns={columns}
          onRowClick={(row) => navigate('/action/' + row.id)}
        />
      </div>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  gridTitle: {
    ...fonts.regular14,
    color: palette.gray700,
  },
  gridValue: {
    ...fonts.semiBold14,
    whiteSpace: 'pre-line',
  },
};
