import imagePlaceholder from '../../assets/images/image-placeholder.svg';
import closeIcon from '../../assets/icons/close-gray.svg';
import linkIcon from '../../assets/icons/link.svg';
import { useMainData } from '../../providers/MainDataProvider';

interface DocumentSlotItem {
  uri?: string;
  fileName?: string;
  onDelete?: () => void;
  handleFileChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const DocumentSlot: React.FC<DocumentSlotItem> = ({
  uri,
  fileName,
  onDelete,
  handleFileChange,
}) => {
  const { primaryColors } = useMainData();

  return (
    <>
      <div style={{ position: 'relative' }}>
        {uri ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              color: primaryColors.primary,
              textDecoration: 'none',
            }}
          >
            <a
              href={uri}
              target="_blank"
              rel="noreferrer"
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                color: primaryColors.primary,
                textDecoration: 'none',
              }}
            >
              <img
                src={linkIcon}
                alt="link"
                style={{
                  width: 16,
                  height: 16,
                  cursor: 'pointer',
                }}
              />
              <p style={{ margin: 0 }}>{fileName}</p>
            </a>
            {onDelete && (
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={onDelete}
              >
                <img
                  src={closeIcon}
                  alt="close"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <label htmlFor="document-picker">
              <img
                src={imagePlaceholder}
                alt="placeholder"
                // onClick={onClick}
                style={{ cursor: 'pointer' }}
              />
            </label>
            <input
              id="document-picker"
              type="file"
              accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
              multiple
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </>
        )}
      </div>
    </>
  );
};
