import React, { useEffect } from 'react';
import { CustomTextInput } from '../common/CustomTextInput';
import {
  CauseType,
  DateRange,
  ReportType,
  RiskRange,
  SearchFilterType,
} from '../../types/common/main';
import { defaultMargin } from '../../styles/styles';
import { useFlightPhases } from '../../hooks/useFlightPhases';
import { CustomDropdown } from '../common/CustomDropdown';
import { s } from '../../strings/strings';
import { useReportSubjects } from '../../hooks/useReportSubjects';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  actionStatus,
  eventKinds,
  eventTypes,
  flightKinds,
  flightTypes,
  REX_DESIGNATION_CPS,
  REX_DESIGNATION_CS,
  REX_TYPE_CPS,
  REX_TYPE_CS,
} from '../../constants';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import { capitalize } from '../../utils/string';
import { useAuth } from '../../providers/AuthProvider';

interface CustomSearchFilterInputProps {
  valueState: [any, React.Dispatch<any>];
  type?: SearchFilterType;
  placeholder?: string;
}

export const CustomSearchFilterInput: React.FC<
  CustomSearchFilterInputProps
> = ({ valueState, type, placeholder }) => {
  const [value, setValue] = valueState;

  const { flightPhases } = useFlightPhases();
  const { reportSubjects } = useReportSubjects();
  const { myEntities } = useAuth();

  const clubs = myEntities?.filter(
    (entity) =>
      entity?.rex_type === 20 ||
      REX_DESIGNATION_CPS?.includes(entity?.rex_designation || '') ||
      REX_TYPE_CPS?.includes(entity?.rex_type || 0) ||
      REX_TYPE_CPS?.includes(entity?.rex_role_type || 0) ||
      REX_DESIGNATION_CS?.includes(entity?.rex_designation || '') ||
      REX_TYPE_CS?.includes(entity?.rex_type || 0) ||
      REX_TYPE_CS?.includes(entity?.rex_role_type || 0)
  );

  return (
    <>
      <div
        style={{
          paddingTop: defaultMargin,
          paddingBottom: defaultMargin,
        }}
      >
        {(type === 'text' || type === 'number') && (
          <CustomTextInput
            state={[value, setValue]}
            type={type}
            placeholder={placeholder}
          />
        )}
        {type === 'rexType' && (
          <FormControl
            style={{
              display: 'flex',
              flexGrow: 1,
            }}
          >
            <RadioGroup
              row
              name="rex-type-selector"
              value={value || s.common.noObject}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setValue((event.target as HTMLInputElement).value);
              }}
              style={{
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                value={ReportType.Anomaly}
                control={<Radio />}
                label={ReportType.Anomaly}
              />
              <FormControlLabel
                value={ReportType.GoodPractice}
                control={<Radio />}
                label={ReportType.GoodPractice}
              />
              <FormControlLabel
                value={s.common.noObject}
                control={<Radio />}
                label={s.common.noObject}
              />
            </RadioGroup>
          </FormControl>
        )}
        {type === 'flightPhase' && (
          <CustomDropdown
            placeholder={s.rex.newRex.placeholders.selectValue}
            state={[
              value?.id,
              (index: number) => {
                setValue(flightPhases?.find((fp) => fp.id === index));
              },
            ]}
            values={
              flightPhases?.map((flightPhase, index) => {
                return {
                  value: flightPhase.id,
                  label: `${index + 1} - ${flightPhase.name}`,
                };
              }) || []
            }
          />
        )}
        {type === 'myClubs' && (
          <CustomDropdown
            placeholder={s.rex.newRex.placeholders.selectValue}
            state={[
              value?.ree_idx,
              (index: number) => {
                setValue(clubs?.find((c, i) => c.ree_idx === index));
              },
            ]}
            values={
              clubs?.map((club) => {
                return {
                  value: club.ree_idx,
                  label: club.ree_designation,
                };
              }) || []
            }
          />
        )}
        {type === 'rexSubject' && (
          <CustomDropdown
            placeholder={s.rex.newRex.placeholders.selectValue}
            state={[
              value?.id,
              (index: number) => {
                setValue(reportSubjects?.find((rs) => rs.id === index));
              },
            ]}
            values={
              reportSubjects?.map((subject) => {
                return {
                  value: subject.id,
                  label: subject.name,
                };
              }) || []
            }
          />
        )}
        {type === 'dateRange' && (
          <>
            <div style={{ display: 'flex' }}>
              <CustomTextInput
                state={[
                  value?.min,
                  (date: string) => {
                    setValue((oldValue: DateRange) => ({
                      ...oldValue,
                      min: date,
                    }));
                  },
                ]}
                type="date"
                placeholder={s.common.placeholders.beginningOfRange}
                inputStyle={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              />
              <CustomTextInput
                state={[
                  value?.max,
                  (date: string) => {
                    setValue((oldValue: DateRange) => ({
                      ...oldValue,
                      max: date,
                    }));
                  },
                ]}
                type="date"
                placeholder={s.common.placeholders.endOfRange}
                inputStyle={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              />
            </div>
          </>
        )}
        {type === 'flightKind' && (
          <CustomDropdown
            placeholder={s.rex.newRex.placeholders.selectValue}
            state={[
              value,
              (value: string) => {
                setValue(value);
              },
            ]}
            values={
              flightKinds?.map((flightKind) => {
                return {
                  value: flightKind,
                  label: flightKind,
                };
              }) || []
            }
          />
        )}
        {type === 'flightType' && (
          <CustomDropdown
            placeholder={s.rex.newRex.placeholders.selectValue}
            state={[
              value,
              (value: string) => {
                setValue(value);
              },
            ]}
            values={
              flightTypes?.map((flightType) => {
                return {
                  value: flightType,
                  label: flightType,
                };
              }) || []
            }
          />
        )}
        {type === 'eventKind' && (
          <CustomDropdown
            placeholder={s.rex.newRex.placeholders.selectValue}
            state={[
              value,
              (value: string) => {
                setValue(value);
              },
            ]}
            values={
              eventKinds?.map((eventKind) => {
                return {
                  value: eventKind,
                  label: eventKind,
                };
              }) || []
            }
          />
        )}
        {type === 'eventType' && (
          <CustomDropdown
            placeholder={s.rex.newRex.placeholders.selectValue}
            state={[
              value,
              (value: string) => {
                setValue(value);
              },
            ]}
            values={
              eventTypes?.map((eventType) => {
                return {
                  value: eventType,
                  label: eventType,
                };
              }) || []
            }
          />
        )}
        {type === 'riskRange' && (
          <>
            <p
              style={{
                ...fonts.medium14,
                color: palette.gray700,
                borderColor: palette.gray200,
              }}
            >
              {capitalize(s.common.between)}
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomDropdown
                placeholder={s.rex.newRex.placeholders.selectValue}
                style={{ display: 'contents' }}
                state={[
                  value?.min,
                  (value: string) => {
                    setValue((oldValue: RiskRange) => ({
                      ...oldValue,
                      min: value,
                    }));
                  },
                ]}
                values={
                  ['1', '2', '3', '4', '5']?.map((risk) => {
                    return {
                      value: risk,
                      label: risk,
                    };
                  }) || []
                }
              />
              <p
                style={{
                  ...fonts.medium14,
                  color: palette.gray700,
                  marginLeft: defaultMargin,
                  marginRight: defaultMargin,
                  borderColor: palette.gray200,
                }}
              >
                {s.common.and}
              </p>
              <CustomDropdown
                placeholder={s.rex.newRex.placeholders.selectValue}
                style={{ display: 'contents' }}
                state={[
                  value?.max,
                  (value: string) => {
                    setValue((oldValue: RiskRange) => ({
                      ...oldValue,
                      max: value,
                    }));
                  },
                ]}
                values={
                  ['1', '2', '3', '4', '5']?.map((risk) => {
                    return {
                      value: risk,
                      label: risk,
                    };
                  }) || []
                }
              />
            </div>
          </>
        )}
        {type === 'causeType' && (
          <FormControl
            style={{
              display: 'flex',
              flexGrow: 1,
            }}
          >
            <RadioGroup
              row
              name="cause-type-filter-selector"
              value={value || s.common.noObject}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setValue((event.target as HTMLInputElement).value);
              }}
              style={{
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                value={CauseType.MainCause}
                control={<Radio />}
                label={CauseType.MainCause}
              />
              <FormControlLabel
                value={CauseType.ContributingFactor}
                control={<Radio />}
                label={CauseType.ContributingFactor}
              />
              <FormControlLabel
                value={s.common.noObject}
                control={<Radio />}
                label={s.common.noObject}
              />
            </RadioGroup>
          </FormControl>
        )}
        {type === 'actionStatus' && (
          <CustomDropdown
            placeholder={s.rex.newRex.placeholders.selectValue}
            state={[
              value,
              (value: string) => {
                setValue(value);
              },
            ]}
            values={
              actionStatus?.map((actionStatus) => {
                return {
                  value: actionStatus,
                  label: actionStatus,
                };
              }) || []
            }
          />
        )}
        {type === 'yesNo' && (
          <FormControl
            style={{
              display: 'flex',
              flexGrow: 1,
            }}
          >
            <RadioGroup
              row
              name="yes-no-filter-selector"
              value={value || s.common.noObject}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setValue((event.target as HTMLInputElement).value);
              }}
              style={{
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                value={s.common.yes}
                control={<Radio />}
                label={s.common.yes}
              />
              <FormControlLabel
                value={s.common.no}
                control={<Radio />}
                label={s.common.no}
              />
              <FormControlLabel
                value={s.common.noObject}
                control={<Radio />}
                label={s.common.noObject}
              />
            </RadioGroup>
          </FormControl>
        )}
      </div>
    </>
  );
};
