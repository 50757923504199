import { Tab, Tabs } from '@mui/material';
import { DashboardLicenseeSection } from '../../components/dashboard/DashboardLicenseeSection';
import { BasicPage } from '../../components/templates/BasicPage';
import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import { fonts } from '../../styles/fonts';
import { defaultMargin, largeMargin } from '../../styles/styles';
import { useEffect, useState } from 'react';
import { useAuth } from '../../providers/AuthProvider';
import { DashboardCPSSection } from '../../components/dashboard/DashboardCPSSection';
import { DashboardCSSection } from '../../components/dashboard/DashboardCSSection';
import { CustomDropdown } from '../../components/common/CustomDropdown';
import { AxiosResponse } from 'axios';
import { api } from '../../utils/api';
import { useQuery } from 'react-query';
import {
  REX_DESIGNATION_CPS,
  REX_DESIGNATION_CS,
  REX_TYPE_CPS,
  REX_TYPE_CS,
} from '../../constants';
import { useMainData } from '../../providers/MainDataProvider';

const DashboardTabs = {
  CS: s.dashboard.tabs.cs,
  CPS: s.dashboard.tabs.cps,
  LICENSEE: s.dashboard.tabs.licensee,
};

export const DashboardHomePage = () => {
  const { isTestEnvironment } = useMainData();
  const { user, myEntities } = useAuth();
  const [isCPS, setIsCPS] = useState<boolean>(false);
  const [isCS, setIsCS] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<string>(
    DashboardTabs.LICENSEE
  );
  const [selectedEntityIdx, setSelectedEntityIdx] = useState<string>();

  const { data: entityChildren } = useQuery(
    [
      'entity',
      selectedEntityIdx,
      'children',
      ...(isTestEnvironment ? ['test'] : []),
    ],
    async () =>
      api
        .get(`smile/entity/${selectedEntityIdx}/children`)
        .then(({ data }: AxiosResponse<string[], any>) => data),
    {
      enabled: !!selectedEntityIdx,
      staleTime: 1000 * 60,
    }
  );

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    let isCPS = false;
    let isCS = false;

    if (selectedEntityIdx) {
      myEntities
        ?.filter((entity) => entity?.ree_idx?.toString() === selectedEntityIdx)
        ?.forEach((rex) => {
          if (
            rex?.rex_designation === REX_DESIGNATION_CPS ||
            REX_TYPE_CPS.includes(rex?.rex_type || 0) ||
            REX_TYPE_CPS.includes(rex?.rex_role_type || 0)
          )
            isCPS = true;

          if (
            rex?.rex_designation === REX_DESIGNATION_CS ||
            REX_TYPE_CS.includes(rex?.rex_type || 0) ||
            REX_TYPE_CS.includes(rex?.rex_role_type || 0)
          )
            isCS = true;
        });
    }

    setIsCPS(isCPS);
    setIsCS(isCS);
  }, [myEntities, selectedEntityIdx]);

  useEffect(() => {
    const firstClub = myEntities?.at(0);
    if (firstClub?.ree_idx) setSelectedEntityIdx(firstClub.ree_idx?.toString());
  }, [myEntities]);

  return (
    <>
      <BasicPage style={{ backgroundColor: palette.gray50 }}>
        <div>
          <p
            style={{ ...fonts.bold30, margin: 0, marginBottom: defaultMargin }}
          >
            {s.dashboard.title(user?.name || '')}
          </p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              style={{
                color: palette.darkBlue600,
                marginBottom: defaultMargin,
              }}
            >
              {[
                ...(isCPS ? [DashboardTabs.CPS] : []),
                ...(isCS ? [DashboardTabs.CS] : []),
                DashboardTabs.LICENSEE,
              ]?.map((tabName, index) => {
                return (
                  <Tab
                    key={index}
                    label={tabName}
                    value={tabName}
                    style={{ textTransform: 'capitalize' }}
                  />
                );
              })}
            </Tabs>
            <div>
              <CustomDropdown
                label={''}
                state={[selectedEntityIdx, setSelectedEntityIdx]}
                values={
                  myEntities?.map((entity) => ({
                    value: entity.ree_idx?.toString(),
                    label: entity.ree_designation,
                  })) || []
                }
              />
            </div>
          </div>
          <div
            style={{
              marginLeft: largeMargin,
              marginRight: largeMargin,
            }}
          >
            {isCPS && selectedTab === DashboardTabs.CPS && (
              <DashboardCPSSection
                selectedEntityIdx={selectedEntityIdx}
                entityChildren={entityChildren}
              />
            )}
            {isCS && selectedTab === DashboardTabs.CS && (
              <DashboardCSSection
                selectedEntityIdx={selectedEntityIdx}
                entityChildren={entityChildren}
              />
            )}
            {selectedTab === DashboardTabs.LICENSEE && (
              <DashboardLicenseeSection
                selectedEntityIdx={selectedEntityIdx}
                entityChildren={entityChildren}
              />
            )}
          </div>
        </div>
      </BasicPage>
    </>
  );
};
