import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SearchType } from '../types/common/main';
import { SearchFilters } from '../types/common/api';
import { useParams } from 'react-router-dom';

interface SearchProps {
  children: React.ReactNode;
}

interface SearchContextType {
  searchType: SearchType;
  setSearchType: Dispatch<React.SetStateAction<SearchType>>;
  searchValue: string;
  setSearchValue: Dispatch<React.SetStateAction<string>>;
  searchParams: SearchFilters;
  setSearchParams: Dispatch<React.SetStateAction<SearchFilters>>;
  searchResults: any[] | undefined;
  setSearchResults: Dispatch<React.SetStateAction<any[] | undefined>>;
  isAdvancedSearch: boolean;
  setIsAdvancedSearch: Dispatch<React.SetStateAction<boolean>>;
}

const SearchContext = createContext<SearchContextType>(undefined!!);

export const SearchProvider = ({ children }: SearchProps) => {
  const { searchType: searchTypeParams } = useParams();
  const defaultSearchType =
    searchTypeParams && ['rex', 'cause', 'action'].includes(searchTypeParams)
      ? (searchTypeParams as SearchType)
      : 'rex';

  const [searchType, setSearchType] = useState<SearchType>(defaultSearchType);
  const [searchValue, setSearchValue] = useState('');
  const [searchParams, setSearchParams] = useState<SearchFilters>({});
  const [searchResults, setSearchResults] = useState<any[] | undefined>([]);
  const [isAdvancedSearch, setIsAdvancedSearch] = useState(false);

  useEffect(() => {
    setSearchResults([]);
  }, [searchType]);

  return (
    <SearchContext.Provider
      value={{
        searchType,
        setSearchType,
        searchValue,
        setSearchValue,
        searchParams,
        setSearchParams,
        searchResults,
        setSearchResults,
        isAdvancedSearch,
        setIsAdvancedSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
