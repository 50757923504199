import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MySmileEntity, SmileUser, User } from '../types/common/api';
import {
  api,
  updateApiAuthorization,
  updateBaseUrl,
  updateSmileApiAuthorization,
} from '../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useMainData } from './MainDataProvider';
import {
  FFA_INDEX,
  REX_DESIGNATION_CPS,
  REX_DESIGNATION_CS,
  REX_TYPE_CPS,
  REX_TYPE_CS,
} from '../constants';
import environment from '../environment';

interface AuthContextType {
  login: (licenseId: string, code: number) => Promise<void>;
  smileLogin: (
    licenseId: string,
    password: string
  ) => Promise<{
    disable_mfa: boolean;
  }>;
  logout: () => Promise<void>;
  user?: User;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  smileUser?: SmileUser;
  setSmileUser: React.Dispatch<React.SetStateAction<SmileUser | undefined>>;
  hasCPSPermission: boolean;
  hasCSPermission: boolean;
  updateUserSettings: (
    allow_immediate_club_notification?: boolean,
    allow_weekly_notification_new_rex?: boolean,
    allow_newsletter?: boolean
  ) => Promise<void>;
  myEntities: MySmileEntity[];
  getClubsByRole: (
    roleDesignation?: string[],
    roleType?: number[]
  ) => MySmileEntity[];
  isMemberOfClub: (entityIdx: string) => boolean;
  clubsWithCPSAccess: MySmileEntity[];
}

const AuthContext = createContext<AuthContextType>(undefined!!);

export const AuthProvider = ({ children }: any) => {
  const location = useLocation();
  const { isTestEnvironment } = useMainData();
  const navigate = useNavigate();

  const [user, setUser] = useState<User>();
  const [smileUser, setSmileUser] = useState<SmileUser>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasCPSPermission, setHasCPSPermission] = useState<boolean>(false);
  const [hasCSPermission, setHasCSPermission] = useState<boolean>(false);
  const [myEntities, setMyEntities] = useState<MySmileEntity[]>([]);

  useEffect(() => {
    let isCPS = false;
    let isCS = false;
    const rexs = smileUser?.rex;

    rexs?.forEach((rex) => {
      if (
        rex?.rex_designation === REX_DESIGNATION_CPS ||
        REX_TYPE_CPS.includes(rex?.rex_type)
      )
        isCPS = true;
      if (
        rex?.rex_designation === REX_DESIGNATION_CS ||
        REX_TYPE_CS.includes(rex?.rex_type)
      )
        isCS = true;
    });

    setHasCPSPermission(isCPS);
    setHasCSPermission(isCS);
  }, [smileUser]);

  const SMILE_TOKEN_KEY = 'smileToken' + (isTestEnvironment ? '_test' : '');
  const SMILE_LICENSE_NUMBER_KEY =
    'smileLicenseNumber' + (isTestEnvironment ? '_test' : '');
  const TOKEN_KEY = 'rexffaToken' + (isTestEnvironment ? '_test' : '');

  useEffect(() => {
    if (!isLoading) {
      if (!user || !smileUser) {
        navigate('/login');
      } else if (location?.pathname === '/') {
        navigate('/dashboard');
      }
    }
  }, [isLoading, smileUser, user, navigate, location?.pathname]);

  useEffect(() => {
    const token = window.localStorage.getItem(TOKEN_KEY);
    const smileToken = window.localStorage.getItem(SMILE_TOKEN_KEY);
    const smileLicenseNumber = window.localStorage.getItem(
      SMILE_LICENSE_NUMBER_KEY
    );
    if (!token || !smileToken || !smileLicenseNumber) {
      setIsLoading(false);
      return;
    }
    updateApiAuthorization(token);
    updateSmileApiAuthorization(smileToken, smileLicenseNumber);
    updateBaseUrl(
      isTestEnvironment ? environment.testApiUrl : environment.apiUrl
    );

    whoami();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchEntities = async () => {
      const entities: MySmileEntity[] = await api
        .get('smile/entities')
        .then(({ data }) => data);

      const myEntities =
        entities?.findIndex((e) => e.ree_idx === parseInt(FFA_INDEX)) === -1
          ? [
              {
                ree_idx: parseInt(FFA_INDEX),
                ree_designation: 'FFA',
              },
              ...entities,
            ]
          : entities;

      setMyEntities(myEntities);
    };

    fetchEntities();
  }, [smileUser]);

  const getClubsByRole = useCallback(
    (roleDesignation?: string[], roleType?: number[]) => {
      return myEntities?.filter(
        (entity) =>
          roleDesignation?.includes(entity?.rex_designation || '') ||
          roleType?.includes(entity?.rex_type || 0) ||
          roleType?.includes(entity?.rex_role_type || 0)
      );
    },
    [myEntities]
  );

  const isMemberOfClub = useCallback(
    (entityIdx: string) => {
      return (
        myEntities?.findIndex(
          (entity) => entity.ree_idx.toString() === entityIdx
        ) !== -1
      );
    },
    [myEntities]
  );

  const clubsWithCPSAccess = useMemo(() => {
    return getClubsByRole([REX_DESIGNATION_CPS], REX_TYPE_CPS);
  }, [getClubsByRole]);

  const whoami = () => {
    return Promise.all([whoamiRexFFA(), whoamiSmile()]).finally(() => {
      setIsLoading(false);
    });
  };

  const whoamiRexFFA = () => {
    return api
      .get('auth/whoami')
      .then(({ data }) => {
        setUser(data);
      })
      .catch(() => setUser(undefined));
  };

  const whoamiSmile = () => {
    return api
      .get(`smile/whoami`)
      .then(({ data }) => {
        setSmileUser(data);
      })
      .catch(() => setSmileUser(undefined));
  };

  const setToken = (token: string) => {
    updateApiAuthorization(token);
    window.localStorage.setItem(TOKEN_KEY, token);
  };

  const removeToken = async () => {
    updateApiAuthorization(null);
    window.localStorage.removeItem(TOKEN_KEY);
  };

  const setSmileToken = (token: string, licenseNumber: string) => {
    updateSmileApiAuthorization(token, licenseNumber);
    window.localStorage.setItem(SMILE_TOKEN_KEY, token);
    window.localStorage.setItem(SMILE_LICENSE_NUMBER_KEY, licenseNumber);
  };

  const removeSmileToken = async () => {
    updateSmileApiAuthorization(null, null);
    window.localStorage.removeItem(SMILE_TOKEN_KEY);
    window.localStorage.removeItem(SMILE_LICENSE_NUMBER_KEY);
  };

  const login = (licenseId: string, code: number) => {
    return api
      .post(`auth/login/website`, {
        license_id: licenseId,
        code,
      })
      .then(async ({ data }) => {
        setToken(data?.access_token);
        const mfa_token = data?.mfa_token;
        Cookies.set(
          'mfa_token' + (isTestEnvironment ? '_test' : ''),
          mfa_token,
          {
            expires: 365,
          }
        );
        whoamiRexFFA();
      });
  };

  const smileLogin = (licenseId: string, password: string) => {
    const mfa_token = Cookies.get(
      'mfa_token' + (isTestEnvironment ? '_test' : '')
    );

    return api
      .post(`auth/smile/login`, {
        license_id: licenseId,
        password: password,
        mfa_token,
      })
      .then(async ({ data }) => {
        const smileUser = data?.smile_user;
        const accessToken = data?.access_token;

        setSmileToken(smileUser?.lic_token, smileUser?.lic_num);
        if (accessToken) setToken(accessToken);

        whoamiSmile();
        if (accessToken) {
          whoamiRexFFA();
          return { disable_mfa: true };
        }

        return { disable_mfa: false };
      });
  };

  const updateUserSettings = (
    allow_immediate_club_notification?: boolean,
    allow_weekly_notification_new_rex?: boolean,
    allow_newsletter?: boolean
  ) => {
    return api
      .put(`user`, {
        allow_immediate_club_notification,
        allow_weekly_notification_new_rex,
        allow_newsletter,
      })
      .then(({ data }: { data: User }) => {
        setUser(data);
      });
  };

  const logout = async () => {
    await removeSmileToken();
    await removeToken();
    setUser(undefined);
    setSmileUser(undefined);
  };

  if ((!user || !smileUser) && location?.pathname !== '/login') return null;

  return (
    <AuthContext.Provider
      value={{
        login,
        smileLogin,
        logout,
        user,
        setUser,
        smileUser,
        setSmileUser,
        updateUserSettings,
        hasCPSPermission,
        hasCSPermission,
        myEntities,
        getClubsByRole,
        isMemberOfClub,
        clubsWithCPSAccess,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
