import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { DashboardPieChart } from '../../charts/DashboardPieChart';
import { api } from '../../../utils/api';
import { s } from '../../../strings/strings';
import { DashboardBarChart } from '../../charts/DashboardBarChart';
import { DashboardGeneralStatsCard } from './DashboardGeneralStatsCard';
import { getEntitiesStatsParam } from '../../../utils/stats';

interface DashboardChartsProps {
  selectedEntityIdx?: string;
  entityChildren?: string[];
}

export const DashboardCharts: React.FC<DashboardChartsProps> = ({
  selectedEntityIdx,
  entityChildren,
}) => {
  const entitiesIdx = getEntitiesStatsParam(selectedEntityIdx, entityChildren);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const gridXs = isSmallScreen ? 12 : 6;

  return (
    <>
      <Grid item xs={gridXs}>
        <DashboardGeneralStatsCard
          selectedEntityIdx={selectedEntityIdx}
          entityChildren={entityChildren}
        />
      </Grid>
      <Grid item xs={gridXs}>
        <DashboardPieChart
          entitiesIdx={entitiesIdx}
          title={s.dashboard.charts.publicationLevel}
          fetchFunction={(params) => api.get(`stats/rex/visibility${params}`)}
        />
      </Grid>
      <Grid item xs={gridXs}>
        <DashboardBarChart
          entitiesIdx={entitiesIdx}
          title={s.dashboard.charts.initialRisk}
          fetchFunction={(params) => api.get(`stats/rex/initial_risk${params}`)}
          disableRexType
        />
      </Grid>
      <Grid item xs={gridXs}>
        <DashboardBarChart
          entitiesIdx={entitiesIdx}
          title={s.dashboard.charts.residualRisk}
          fetchFunction={(params) =>
            api.get(`stats/rex/residual_risk${params}`)
          }
          disableRexType
        />
      </Grid>
      <Grid item xs={gridXs}>
        <DashboardPieChart
          entitiesIdx={entitiesIdx}
          title={s.dashboard.charts.rexSubject}
          fetchFunction={(params) => api.get(`stats/rex/subject${params}`)}
        />
      </Grid>
      <Grid item xs={gridXs}>
        <DashboardPieChart
          entitiesIdx={entitiesIdx}
          title={s.dashboard.charts.flightPhase}
          fetchFunction={(params) => api.get(`stats/rex/flight-phase${params}`)}
        />
      </Grid>
      <Grid item xs={gridXs}>
        <DashboardPieChart
          entitiesIdx={entitiesIdx}
          title={s.dashboard.charts.urgency}
          fetchFunction={(params) => api.get(`stats/rex/urgency${params}`)}
        />
      </Grid>
      <Grid item xs={gridXs}>
        <DashboardPieChart
          entitiesIdx={entitiesIdx}
          title={s.dashboard.charts.flightKind}
          fetchFunction={(params) => api.get(`stats/rex/flight-kind${params}`)}
        />
      </Grid>
      <Grid item xs={gridXs}>
        <DashboardPieChart
          entitiesIdx={entitiesIdx}
          title={s.dashboard.charts.flightRegime}
          fetchFunction={(params) =>
            api.get(`stats/rex/flight-regime${params}`)
          }
        />
      </Grid>
      <Grid item xs={gridXs}>
        <DashboardPieChart
          entitiesIdx={entitiesIdx}
          title={s.dashboard.charts.flightType}
          fetchFunction={(params) => api.get(`stats/rex/flight-type${params}`)}
        />
      </Grid>
      <Grid item xs={12}>
        <DashboardBarChart
          entitiesIdx={entitiesIdx}
          width={1000}
          title={s.dashboard.charts.eventType}
          fetchFunction={(params) => api.get(`stats/rex/event-type${params}`)}
        />
      </Grid>
      <Grid item xs={gridXs}>
        <DashboardBarChart
          entitiesIdx={entitiesIdx}
          title={s.dashboard.charts.eventKind}
          fetchFunction={(params) => api.get(`stats/rex/event-kind${params}`)}
        />
      </Grid>
      <Grid item xs={gridXs}>
        <DashboardPieChart
          entitiesIdx={entitiesIdx}
          title={s.dashboard.charts.associatedActions}
          fetchFunction={(params) => api.get(`stats/rex/action${params}`)}
        />
      </Grid>
    </>
  );
};
