import React from 'react';
import { CustomModal } from '../common/CustomModal';
import { fonts } from '../../styles/fonts';
import { palette } from '../../styles/colors';
import { s } from '../../strings/strings';
import { CustomButton } from '../common/CustomButton';
import { defaultMargin, smallMargin } from '../../styles/styles';
import downloadIcon from '../../assets/icons/blue-download.svg';
import { GridApiCommunity } from '@mui/x-data-grid/internals';
import Excel from 'exceljs';

interface ExportResultsModalProps {
  open: boolean;
  onClose: () => void;
  apiRef: React.MutableRefObject<GridApiCommunity>;
}

export const ExportResultsModal: React.FC<ExportResultsModalProps> = ({
  open,
  onClose,
  apiRef,
}) => {
  const generateFileName = () => {
    const now = new Date();
    return `export-rexffa-${now
      .toISOString()
      ?.replaceAll('-', '')
      ?.split('T')
      ?.shift()}`;
  };

  const exportAsCsv = () => {
    apiRef.current.exportDataAsCsv({
      fileName: generateFileName(),
      delimiter: ';',
    });
  };

  const exportAsExcel = async () => {
    const csvData = apiRef.current.getDataAsCsv();

    const excelData = csvData?.split('\n')?.map((row) => row?.split(',')) || [];

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('REX FFA');

    worksheet.addRows(excelData);

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = generateFileName() + '.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={onClose}
        closeCross
        style={{ minWidth: 600 }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: defaultMargin + smallMargin,
          }}
        >
          <p
            style={{
              ...fonts.bold20,
              textAlign: 'center',
              marginTop: 0,
            }}
          >
            {s.modals.exportResults.title}
          </p>
          <p
            style={{
              ...fonts.regular16,
              color: palette.gray700,
              textAlign: 'center',
              marginTop: 0,
            }}
          >
            {s.modals.exportResults.subtitle}
          </p>
          <div style={{ display: 'flex', marginTop: defaultMargin }}>
            <CustomButton
              title={s.modals.exportResults.spreadsheet}
              onClick={exportAsExcel}
              style={{
                margin: smallMargin,
                paddingLeft: defaultMargin + smallMargin,
                paddingRight: defaultMargin + smallMargin,
                border: `1px solid ${palette.darkBlue600}`,
                backgroundColor: palette.darkBlue50,
                color: palette.darkBlue600,
              }}
              iconSrc={downloadIcon}
              iconPosition="right"
            />
            <CustomButton
              title={s.modals.exportResults.csv}
              onClick={exportAsCsv}
              style={{
                margin: smallMargin,
                paddingLeft: defaultMargin + smallMargin,
                paddingRight: defaultMargin + smallMargin,
                border: `1px solid ${palette.darkBlue600}`,
                backgroundColor: palette.darkBlue50,
                color: palette.darkBlue600,
              }}
              iconSrc={downloadIcon}
              iconPosition="right"
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};
