import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { fonts } from '../../styles/fonts';
import { palette } from '../../styles/colors';
import { defaultMargin, mediumRadius, tinyMargin } from '../../styles/styles';
import { s } from '../../strings/strings';

interface IncludeImagesSelectorProps {
  state: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  style?: React.CSSProperties;
}

export const IncludeImagesSelector: React.FC<IncludeImagesSelectorProps> = ({
  state,
  style,
}) => {
  const [value, setValue] = state;

  return (
    <>
      <div
        style={{
          width: '100%',
          position: 'relative',
          ...style,
        }}
      >
        <p
          style={{
            ...fonts.medium14,
            color: palette.gray700,
            margin: 0,
            marginBottom: tinyMargin,
          }}
        >
          {s.rex.newCresag.descriptionSection.includeImages}
        </p>
        <FormControl
          style={{
            display: 'flex',
            flexGrow: 1,
            border: `1px solid ${palette.gray200}`,
            borderRadius: mediumRadius,
            padding: `2px ${defaultMargin}px`,
          }}
        >
          <RadioGroup
            row
            name="include-images-selector"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(event.target.value === 'true');
            }}
          >
            <FormControlLabel value={true} control={<Radio />} label={'Oui'} />
            <FormControlLabel value={false} control={<Radio />} label={'Non'} />
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};
