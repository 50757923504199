export enum RexFlowScreen {
  ChooseClub,
  ChooseSubject,
  ChooseDate,
  ChooseFlightPhase,
  AddDetails,
  AddPhotos,
  RexSummary,
}

export const ReportType = {
  Anomaly: 'Anomalie',
  GoodPractice: 'Bonne pratique',
};

export const CauseType = {
  MainCause: 'Cause Principale',
  ContributingFactor: 'Facteur contributif',
};

export const RexStatusList = {
  InProgress: 'En cours',
  Canceled: 'Annulé',
  Ready: 'Prêt',
  WaitingForValidation: 'En attente de validation',
  Published: 'Publié',
  Removed: 'Retiré',
};

export const RexVisibility = {
  FFA: 'FFA',
  CRA: 'CRA',
  CDA: 'CDA',
  Plateforme: 'Plateforme',
  InterClub: 'Inter-club',
  Club: 'Club',
};

export enum ReportStatus {
  Draft,
  Sent,
  Received,
  Processed,
}

export interface BasicFile {
  uri: string;
  type?: string;
  filename?: string;
  file?: File;
}

export interface RexData {
  id?: number;
  reportType: string;
  club?: SelectableListItemData;
  subject?: SelectableListItemData;
  date?: string;
  flightPhase?: SelectableListItemData;
  details?: RexDataDetails;
  images?: BasicFile[];
  //
  status?: ReportStatus;
  response?: string;
  url?: string;
  sentDate?: number;
}

export interface ReportDraft {
  data: RexData;
  date: number;
}

export interface SelectableListItemData {
  id: number;
  name: string;
}

export interface RexDataDetails {
  urgency: SelectableListItemData;
  description?: string;
  recording?: BasicFile;
}

export interface DateRange {
  min?: string;
  max?: string;
}

export interface RiskRange {
  min?: number;
  max?: number;
}

export interface SmileEntityPlateform {
  ree_idx: number;
  ree_type: number;
  ree_code: string;
  ree_designation: string;
}

export interface SmileEntityInterclub {
  ree_idx: number;
  ree_type: number;
  ree_code: string;
  ree_designation: string;
}

export interface SmileEntityRex {
  rex_lic_num: string;
  rex_priorite: number;
  rex_type: string;
  rex_designation: string;
}

export interface SmileEntityAvion {
  av_immat: string;
  av_type: string;
  av_constructeur: string;
  av_modele: string;
  av_oaci: string;
}
export interface Entity {
  env: string;
  ree_type: number;
  ree_code: string;
  ree_designation: string;
  ree_cra: number;
  ree_cra_code: string;
  ree_cra_designation: string;
  ree_cda: number;
  ree_cda_code: string;
  ree_cda_designation: string;
  ree_nb_club: number;
  ree_nb_lic: number;
  plateforme: SmileEntityPlateform[];
  interclub: SmileEntityInterclub[];
  rex: SmileEntityRex[];
  avion: SmileEntityAvion[];
}

export type SearchFilterType =
  | 'text'
  | 'number'
  | 'date'
  | 'dateRange'
  | 'flightPhase'
  | 'myClubs'
  | 'rexSubject'
  | 'rexType'
  | 'flightType'
  | 'flightKind'
  | 'eventType'
  | 'eventKind'
  | 'riskRange'
  | 'yesNo'
  | 'causeType'
  | 'actionStatus';

export type SearchType = 'rex' | 'cause' | 'action';

export type StatsRange = 'threeMonths' | 'twelveMonths' | 'total';
