import { PieChart } from '@mui/x-charts';
import { PieChartProps } from '@mui/x-charts/PieChart/PieChart';
import React from 'react';
import { palette } from '../../../styles/colors';

export const CustomPieChart: React.FC<PieChartProps> = (props) => {
  const dataLength = props?.series?.[0]?.data?.length;
  let style = {
    fontSize: 14,
    intemMarkSize: 10,
    markGap: 5,
    itemGap: 5,
  };

  if (dataLength > 15)
    style = {
      fontSize: 9,
      intemMarkSize: 5,
      markGap: 1,
      itemGap: 1,
    };
  else if (dataLength > 12)
    style = {
      fontSize: 10,
      intemMarkSize: 5,
      markGap: 2,
      itemGap: 2,
    };
  else if (dataLength > 8)
    style = {
      fontSize: 12,
      intemMarkSize: 7,
      markGap: 3,
      itemGap: 3,
    };

  return (
    <>
      <PieChart
        height={200}
        colors={[
          palette.darkBlue500,
          palette.orange300,
          palette.teal800,
          palette.success200,
          palette.error400,
          palette.pink200,
          palette.violet400,
          palette.purple800,
          palette.gray600,
        ]}
        sx={{
          '--ChartsLegend-itemMarkSize': '10px',
          '--ChartsLegend-labelSpacing': '5px',
          '--ChartsLegend-rootSpacing': '10px',
          '--ChartsLegend-rootOffsetY': '0px',
          '& .MuiChartsLegend-label': {
            // ...fonts.regular14,
            color: palette.gray600,
          },
        }}
        slotProps={{
          legend: {
            labelStyle: {
              fontSize: style.fontSize,
              maxWidth: 150,
              display: 'flex',
              flexWrap: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
            hidden: dataLength > 20,
            direction: 'column',
            position: {
              vertical: 'middle',
              horizontal: 'right',
            },
            itemMarkWidth: style.intemMarkSize,
            itemMarkHeight: style.intemMarkSize,
            markGap: style.markGap,
            itemGap: style.itemGap,
          },
        }}
        {...props}
      />
    </>
  );
};
