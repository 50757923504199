export const appVersion = '1.0.8';
export const lastBuildDate = new Date('2025-01-26');

export const flightTypes = [
  'Solo',
  'Instruction',
  'Solo supervisé',
  'Contrôle (FI/FE)',
];
export const flightKinds = [
  'Tour de piste',
  'Local',
  'Navigation',
  'Convoyage',
  'Voltige',
  'Vol montagne',
  'Présentation / meeting',
  'Compétitions (ANR, Rallye, précision, voltige)',
  'Vol découverte / Vol BIA',
  'Vol de contrôle technique',
  'Co-Avionnage',
  'Autre',
];
export const flightRegimes = ['VFR Jour', 'VFR Nuit', 'IFR Jour', 'IFR Nuit'];

export const eventTypes = [
  'ADRM: Aérodrome',
  'AMAN: Manœuvre abrupte',
  'ARC: Contact anormal avec la piste ou le sol',
  'ATM: Événement relatif à des problèmes de communication, navigation ou surveillance',
  'BIRD: Péril aviaire',
  'CABIN: Évènements de sécurité en cabine',
  'CFIT: Impact avec le sol sans perte de contrôle',
  'CTOL: Quasi-collision avec des obstacles au décollage / atterrissage',
  'EVAC: Évacuation',
  'EXTL: Occurrences liées à la charge externe',
  'F-NI: Incendie/fumée non lié à l’impact',
  'F-POST: Incendie/fumée après impact',
  'FUEL: Problème lié au carburant',
  'GCOL: Collision au sol après que l’avion ait commencé à rouler',
  'GTOW: Événements liés au remorquage de planeurs',
  'ICE: Givrage',
  'LALT: Vol à basse altitude',
  'LOC-G: Perte de contrôle – au sol',
  'LOC-I: Perte de contrôle – en vol',
  'LOLI: Vâchage',
  'MAC: Collision/ quasi-collision en vol - Airprox',
  'MED: Problème médical',
  'NAV: Problème lié à la navigation, e.g. égarement',
  'OTHR: Autre',
  'RAMP: Manœuvre au sol avant déplacement de l’avion par son propre moteur',
  'RE: Sortie de piste',
  'RI: Incursion sur piste (véhicule, aéronef ou personne)',
  'SCF-NP: Panne ou mauvais fonctionnement d’un circuit ou d’un composant ne faisant pas partie du groupe motopropulseur',
  'SCF-PP: Panne ou mauvais fonctionnement d’un circuit ou d’un composant faisant partie du groupe motopropulseur',
  'SEC: Lié à la sécurité',
  'TURB: Passage dans une zone de turbulence',
  'UIMC: Conditions IMC imprévues',
  'UNK: Inconnu ou indéterminé',
  'USOS: Atterrissage trop court/trop long',
  'WILD: Impact avec un animal',
  'WSTRW: Cisaillement de vent ou tempête',
];
export const eventKinds = [
  'Accident',
  'Incident grave',
  'Incident',
  'Observation',
  'Evènements hors vol',
  'Autre',
];
export const actionStatus = ['Rejetée', 'En cours', 'Fait'];

export const activityTypes = [
  'ATO (Avion)',
  'ATO (Hélicoptère)',
  'DTO',
  'Pilote de l’aéronef, en location Pilote propriétaire de l’aéronef Travail aérien',
  'Autre',
];

export const eventStatus = [
  'Ouvert, analyse en cours',
  'Clos avec analyse',
  'Clos sans analyse',
];

export const entities = [
  'Assistance en escale',
  'BEA',
  'Concepteur de l’aéronef',
  'Constructeur de l’aéronef',
  'DSNA ou AFIS',
  'État d’immatriculation de l’aéronef',
  'État de l’exploitant de l’aéronef',
  'Exploitant de l’aérodrome',
  'Exploitant de l’aéronef (hors notifiant)',
  'Organisme de gestion du maintien de navigabilité de l’aéronef',
  'Organisme de maintenance de l’aéronef',
  'OSAC',
  'Propriétaire de l’aéronef',
];

// + tous les départements français, sous la forme « XX-YYYY » où XX = numéro du département et YYYY=nom du département

export const MAX_SEVERITY = 5;
export const MAX_PROBABILITY = 5;

const frenchDepartments = [
  ['01', 'Ain', 'Bourg-en-Bresse', 'Auvergne-Rhône-Alpes'],
  ['02', 'Aisne', 'Laon', 'Hauts-de-France'],
  ['03', 'Allier', 'Moulins', 'Auvergne-Rhône-Alpes'],
  ['04', 'Alpes-de-Haute-Provence', 'Digne', "Provence-Alpes-Côte d'Azur"],
  ['05', 'Hautes-Alpes', 'Gap', "Provence-Alpes-Côte d'Azur"],
  ['06', 'Alpes-Maritimes', 'Nice', "Provence-Alpes-Côte d'Azur"],
  ['07', 'Ardèche', 'Privas', 'Auvergne-Rhône-Alpes'],
  ['08', 'Ardennes', 'Charleville-Mézières', 'Grand Est'],
  ['09', 'Ariège', 'Foix', 'Occitanie'],
  ['10', 'Aube', 'Troyes', 'Grand Est'],
  ['11', 'Aude', 'Carcassonne', 'Occitanie'],
  ['12', 'Aveyron', 'Rodez', 'Occitanie'],
  ['13', 'Bouches-du-Rhône', 'Marseille', "Provence-Alpes-Côte d'Azur"],
  ['14', 'Calvados', 'Caen', 'Normandie'],
  ['15', 'Cantal', 'Aurillac', 'Auvergne-Rhône-Alpes'],
  ['16', 'Charente', 'Angoulême', 'Nouvelle-Aquitaine'],
  ['17', 'Charente-Maritime', 'La Rochelle', 'Nouvelle-Aquitaine'],
  ['18', 'Cher', 'Bourges', 'Centre-Val de Loire'],
  ['19', 'Corrèze', 'Tulle', 'Nouvelle-Aquitaine'],
  ['2A', 'Corse-du-Sud', 'Ajaccio', 'Corse'],
  ['2B', 'Haute-Corse', 'Bastia', 'Corse'],
  ['21', "Côte-d'Or", 'Dijon', 'Bourgogne-Franche-Comté'],
  ['22', "Côtes d'Armor", 'Saint-Brieuc', 'Bretagne'],
  ['23', 'Creuse', 'Guéret', 'Nouvelle-Aquitaine'],
  ['24', 'Dordogne', 'Périgueux', 'Nouvelle-Aquitaine'],
  ['25', 'Doubs', 'Besançon', 'Bourgogne-Franche-Comté'],
  ['26', 'Drôme', 'Valence', 'Auvergne-Rhône-Alpes'],
  ['27', 'Eure', 'Évreux', 'Normandie'],
  ['28', 'Eure-et-Loir', 'Chartres', 'Centre-Val de Loire'],
  ['29', 'Finistère', 'Quimper', 'Bretagne'],
  ['30', 'Gard', 'Nîmes', 'Occitanie'],
  ['31', 'Haute-Garonne', 'Toulouse', 'Occitanie'],
  ['32', 'Gers', 'Auch', 'Occitanie'],
  ['33', 'Gironde', 'Bordeaux', 'Nouvelle-Aquitaine'],
  ['34', 'Hérault', 'Montpellier', 'Occitanie'],
  ['35', 'Ille-et-Vilaine', 'Rennes', 'Bretagne'],
  ['36', 'Indre', 'Châteauroux', 'Centre-Val de Loire'],
  ['37', 'Indre-et-Loire', 'Tours', 'Centre-Val de Loire'],
  ['38', 'Isère', 'Grenoble', 'Auvergne-Rhône-Alpes'],
  ['39', 'Jura', 'Lons-le-Saunier', 'Bourgogne-Franche-Comté'],
  ['40', 'Landes', 'Mont-de-Marsan', 'Nouvelle-Aquitaine'],
  ['41', 'Loir-et-Cher', 'Blois', 'Centre-Val de Loire'],
  ['42', 'Loire', 'Saint-Étienne', 'Auvergne-Rhône-Alpes'],
  ['43', 'Haute-Loire', 'Le Puy-en-Velay', 'Auvergne-Rhône-Alpes'],
  ['44', 'Loire-Atlantique', 'Nantes', 'Pays de la Loire'],
  ['45', 'Loiret', 'Orléans', 'Centre-Val de Loire'],
  ['46', 'Lot', 'Cahors', 'Occitanie'],
  ['47', 'Lot-et-Garonne', 'Agen', 'Nouvelle-Aquitaine'],
  ['48', 'Lozère', 'Mende', 'Occitanie'],
  ['49', 'Maine-et-Loire', 'Angers', 'Pays de la Loire'],
  ['50', 'Manche', 'Saint-Lô', 'Normandie'],
  ['51', 'Marne', 'Châlons-en-Champagne', 'Grand Est'],
  ['52', 'Haute-Marne', 'Chaumont', 'Grand Est'],
  ['53', 'Mayenne', 'Laval', 'Pays de la Loire'],
  ['54', 'Meurthe-et-Moselle', 'Nancy', 'Grand Est'],
  ['55', 'Meuse', 'Bar-le-Duc', 'Grand Est'],
  ['56', 'Morbihan', 'Vannes', 'Bretagne'],
  ['57', 'Moselle', 'Metz', 'Grand Est'],
  ['58', 'Nièvre', 'Nevers', 'Bourgogne-Franche-Comté'],
  ['59', 'Nord', 'Lille', 'Hauts-de-France'],
  ['60', 'Oise', 'Beauvais', 'Hauts-de-France'],
  ['61', 'Orne', 'Alençon', 'Normandie'],
  ['62', 'Pas-de-Calais', 'Arras', 'Hauts-de-France'],
  ['63', 'Puy-de-Dôme', 'Clermont-Ferrand', 'Auvergne-Rhône-Alpes'],
  ['64', 'Pyrénées-Atlantiques', 'Pau', 'Nouvelle-Aquitaine'],
  ['65', 'Hautes-Pyrénées', 'Tarbes', 'Occitanie'],
  ['66', 'Pyrénées-Orientales', 'Perpignan', 'Occitanie'],
  ['67', 'Bas-Rhin', 'Strasbourg', 'Grand Est'],
  ['68', 'Haut-Rhin', 'Colmar', 'Grand Est'],
  ['69', 'Rhône', 'Lyon', 'Auvergne-Rhône-Alpes'],
  ['70', 'Haute-Saône', 'Vesoul', 'Bourgogne-Franche-Comté'],
  ['71', 'Saône-et-Loire', 'Mâcon', 'Bourgogne-Franche-Comté'],
  ['72', 'Sarthe', 'Le Mans', 'Pays de la Loire'],
  ['73', 'Savoie', 'Chambéry', 'Auvergne-Rhône-Alpes'],
  ['74', 'Haute-Savoie', 'Annecy', 'Auvergne-Rhône-Alpes'],
  ['75', 'Paris', 'Paris', 'Ile-de-France'],
  ['76', 'Seine-Maritime', 'Rouen', 'Normandie'],
  ['77', 'Seine-et-Marne', 'Melun', 'Ile-de-France'],
  ['78', 'Yvelines', 'Versailles', 'Ile-de-France'],
  ['79', 'Deux-Sèvres', 'Niort', 'Nouvelle-Aquitaine'],
  ['80', 'Somme', 'Amiens', 'Hauts-de-France'],
  ['81', 'Tarn', 'Albi', 'Occitanie'],
  ['82', 'Tarn-et-Garonne', 'Montauban', 'Occitanie'],
  ['83', 'Var', 'Toulon', "Provence-Alpes-Côte d'Azur"],
  ['84', 'Vaucluse', 'Avignon', "Provence-Alpes-Côte d'Azur"],
  ['85', 'Vendée', 'La Roche-sur-Yon', 'Pays de la Loire'],
  ['86', 'Vienne', 'Poitiers', 'Nouvelle-Aquitaine'],
  ['87', 'Haute-Vienne', 'Limoges', 'Nouvelle-Aquitaine'],
  ['88', 'Vosges', 'Épinal', 'Grand Est'],
  ['89', 'Yonne', 'Auxerre', 'Bourgogne-Franche-Comté'],
  ['90', 'Territoire-de-Belfort', 'Belfort', 'Bourgogne-Franche-Comté'],
  ['91', 'Essonne', 'Évry', 'Ile-de-France'],
  ['92', 'Hauts-de-Seine', 'Nanterre', 'Ile-de-France'],
  ['93', 'Seine-Saint-Denis', 'Bobigny', 'Ile-de-France'],
  ['94', 'Val-de-Marne', 'Créteil', 'Ile-de-France'],
  ['95', "Val-D'Oise", 'Pontoise', 'Ile-de-France'],
  ['971', 'Guadeloupe', 'Basse-Terre', 'Guadeloupe'],
  ['972', 'Martinique', 'Fort-de-France', 'Martinique'],
  ['973', 'Guyane', 'Cayenne', 'Guyane'],
  ['974', 'La Réunion', 'Saint-Denis', 'La Réunion'],
  ['976', 'Mayotte', 'Dzaoudzi', 'Mayotte'],
];

export const cresagPlaces = [
  'Afrique',
  'Amérique Centrale et Caraïbes Amérique du Nord',
  'Amérique du Sud',
  'Asie',
  'Europe',
  'Guadeloupe',
  'Guyane Française',
  'Îles Wallis et Futuna Martinique',
  'Mayotte',
  'Moyen Orient Nouvelle-Calédonie',
  'Océanie',
  'Polynésie Française',
  'Réunion',
  'Saint Barthélémy',
  'Saint Martin',
  'Saint Pierre et Miquelon',
  'Terres Australes et Antarctiques Françaises',
  ...frenchDepartments?.map((elem) => `${elem[0]}-${elem[1]}`),
];

export const FFA_INDEX = '654';
export const REX_TYPE_CPS = [4, 9];
export const REX_TYPE_CS = [16, 17];
export const REX_DESIGNATION_CPS = 'Corres. Prévention Sécu.';
export const REX_DESIGNATION_CS = 'Commission sécurité';
