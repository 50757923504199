import { BasicPage } from '../../components/templates/BasicPage';
import loginImage from '../../assets/images/login-image.svg';
import { fonts } from '../../styles/fonts';
import { defaultMargin, largerMargin, smallMargin } from '../../styles/styles';
import { s } from '../../strings/strings';
import { palette } from '../../styles/colors';
import { Switch } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../providers/AuthProvider';
import { useMainData } from '../../providers/MainDataProvider';
import { ChangeEnvDialog } from '../../components/dialog/ChangeEnvDialog';
import { useNavigate } from 'react-router-dom';
import { appVersion, lastBuildDate } from '../../constants';
import { BuildInfo } from '../../components/common/BuildInfo';

export const SettingsPages = () => {
  const { primaryColors, isTestEnvironment, setIsTestEnvironment } =
    useMainData();
  const { user, updateUserSettings, logout } = useAuth();
  const navigate = useNavigate();

  const [isChangeEnvDialogOpen, setIsChangeEnvDialogOpen] = useState(false);
  const [checkedImmediate, setCheckedImmediate] = useState(
    user?.allow_immediate_club_notification || false
  );
  const [checkedWeekly, setCheckedWeekly] = useState(
    user?.allow_weekly_notification_new_rex || false
  );
  const [checkedNewsletter, setCheckedNewsletter] = useState(
    user?.allow_newsletter || false
  );

  useEffect(() => {
    if (user?.allow_immediate_club_notification)
      setCheckedImmediate(user.allow_immediate_club_notification);
    if (user?.allow_weekly_notification_new_rex)
      setCheckedWeekly(user.allow_weekly_notification_new_rex);
    if (user?.allow_newsletter) setCheckedNewsletter(user.allow_newsletter);
  }, [user]);

  return (
    <>
      <BasicPage>
        <p
          style={{
            ...fonts.bold30,
            margin: 0,
            marginTop: defaultMargin,
            marginBottom: defaultMargin,
          }}
        >
          {s.settings.title}
        </p>
        <div style={{ display: 'flex' }}>
          <img
            src={loginImage}
            alt=""
            style={{ height: 'calc(100vh - 197px)' }}
          />
          <div style={{ marginLeft: largerMargin }}>
            <p
              style={{
                ...fonts.bold18,
                color: primaryColors.primary,
                marginTop: defaultMargin,
              }}
            >
              {s.settings.activateTestEnv}
            </p>
            <SwitchItem
              title={s.settings.activateTestEnv}
              description={s.settings.activateTestEnvDescription}
              state={[
                isTestEnvironment,
                () => {
                  setIsChangeEnvDialogOpen(true);
                },
              ]}
              style={{ marginTop: defaultMargin }}
            />
            <p
              style={{
                ...fonts.bold18,
                color: primaryColors.primary,
                marginTop: defaultMargin,
              }}
            >
              {s.settings.notifications.title}
            </p>
            <SwitchItem
              title={s.settings.notifications.immediate.title}
              description={s.settings.notifications.immediate.description}
              state={[
                checkedImmediate,
                (newValue) => {
                  setCheckedImmediate(newValue);
                  updateUserSettings(newValue, undefined, undefined);
                },
              ]}
              style={{ marginTop: defaultMargin }}
            />
            <SwitchItem
              title={s.settings.notifications.weekly.title}
              description={s.settings.notifications.weekly.description}
              state={[
                checkedWeekly,
                (newValue) => {
                  setCheckedWeekly(newValue);
                  updateUserSettings(undefined, newValue, undefined);
                },
              ]}
              style={{ marginTop: defaultMargin }}
            />
            <SwitchItem
              title={s.settings.notifications.newsletter.title}
              description={s.settings.notifications.newsletter.description}
              state={[
                checkedNewsletter,
                (newValue) => {
                  setCheckedNewsletter(newValue);
                  updateUserSettings(undefined, undefined, newValue);
                },
              ]}
              style={{ marginTop: defaultMargin }}
            />
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
            }}
          >
            <BuildInfo />
          </div>
        </div>
      </BasicPage>
      <ChangeEnvDialog
        state={[isChangeEnvDialogOpen, setIsChangeEnvDialogOpen]}
        onConfirm={() => {
          logout().then(() => {
            navigate('/login');
          });
          setIsTestEnvironment(!isTestEnvironment);
        }}
      />
    </>
  );
};

interface SwitchItemProps {
  title: string;
  description: string;
  state: [boolean, (value: boolean) => void];
  style?: React.CSSProperties;
}

const SwitchItem: React.FC<SwitchItemProps> = ({
  title,
  description,
  state,
  style,
}) => {
  const [checked, setChecked] = state;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', ...style }}>
      <Switch
        checked={checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p
          style={{
            ...fonts.medium14,
            margin: 0,
          }}
        >
          {title}
        </p>
        <p
          style={{
            ...fonts.regular12,
            color: palette.gray500,
            margin: 0,
            marginTop: smallMargin,
          }}
        >
          {description}
        </p>
      </div>
    </div>
  );
};
