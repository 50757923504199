import { Autocomplete, TextField } from '@mui/material';
import { fonts } from '../../styles/fonts';
import { palette } from '../../styles/colors';
import { mediumRadius, tinyMargin } from '../../styles/styles';

interface Props {
  label?: string;
  placeholder?: string;
  state: [any, React.Dispatch<React.SetStateAction<any>>];
  options: string[];
}

export const CustomAutoComplete: React.FC<Props> = ({
  label,
  placeholder = '',
  state,
  options,
}) => {
  const [value, setValue] = state;

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      {label && (
        <p
          style={{
            ...fonts.medium14,
            color: palette.gray700,
            margin: 0,
            marginBottom: tinyMargin,
            borderColor: palette.gray200,
          }}
        >
          {label}
        </p>
      )}

      <Autocomplete
        id="autocomplete-field"
        freeSolo
        options={options}
        style={{
          borderColor: 'red',
        }}
        sx={{
          ...fonts.regular16,
          borderColor: palette.gray200,
          '.MuiOutlinedInput-root': {
            height: '47px',
          },

          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
            borderColor: `${palette.gray200} !important`,
            borderRadius: `${mediumRadius}px`,
            display: 'flex',
            alignItems: 'center',
          },
        }}
        value={value}
        onChange={(_, newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            style={{
              borderColor: 'red',
            }}
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
          />
        )}
      />
    </div>
  );
};
