import { appVersion, lastBuildDate } from '../../constants';
import { s } from '../../strings/strings';
import { fonts } from '../../styles/fonts';

export const BuildInfo = () => {
  return (
    <div>
      <p
        style={{
          ...fonts.regular14,
        }}
      >
        {s.common.buildInfo.appVersion} {appVersion} (
        {lastBuildDate.toLocaleDateString('fr-FR')})
      </p>
    </div>
  );
};
